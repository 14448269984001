import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./setup/i18n";
import vuetify from "./setup/vuetify";
import SocioVueComponents from "@socotec.io/socio-vue-components";
import VueSignaturePad from "vue-signature-pad";
import SocioManifest from "../socio-manifest.json";
import PackageJson from "../package.json";
import { initializeTracingAndLogging } from "@socotec.io/socio-vue-components";
import AosComponents from "@socotec.io/socio-aos-component";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import wb from "./registerServiceWorker";
Vue.config.productionTip = false;

//Global
Vue.prototype.$isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
Vue.prototype.$workbox = wb;

Vue.use(SocioVueComponents);
store.$client = socioGrpcClient;
const envConfig = {
  i18nLocale: process.env.VUE_APP_I18N_LOCALE,
  i18nFallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  mapBoxApiKey: process.env.VUE_APP_MAP_BOX,
};
Vue.use(AosComponents, { store, envConfig });
Vue.use(VueSignaturePad);

initializeTracingAndLogging(SocioManifest, PackageJson, { router });
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
