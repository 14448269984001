export const soilGasesDefaultValues = {
  fr: {
    weatherStationLocation:
      "XX, localisée à environ X km au XX du site d’étude",
    weatherSource: "Infoclimat",
    weatherSampleCampaignContext:
      "<ul><li><p>période de faibles précipitations (4,6, 0,2 et 2,6 mm les 3 jours précédents). Ces précipitations autour du bâtiment peuvent induire une augmentation potentielle des flux vers l’air intérieur du bâtiment si la pollution est présente en-dessous du bâtiment,</p></li><li><p>période de températures positives mais basses (températures comprises entre 3 et 9,9°C). Ces conditions de température sont considérées comme neutres pour la volatilisation des gaz du sol (températures &gt; 10°C favorisant la volatilisation)&nbsp;;</p></li><li><p>période de hautes pressions (supérieures à 1013,25 hPa). Ces conditions de fortes pressions atmosphériques sont considérées comme non favorables à la volatilisation des gaz du sol.</p><p>Le XX/XX/20XX, jour des prélèvements de gaz de sols, les conditions suivantes ont été relevées&nbsp;:</p></li><li><p>légères précipitations (environ 0,2 mm sur la journée)&nbsp;: sans effet sur la volatilisation des polluants&nbsp;;</p></li><li><p>températures faibles stables durant le temps de prélèvement (entre 8,4 et 10 °C)&nbsp;: neutre vis-à-vis de la volatilisation des polluants&nbsp;;</p></li><li><p>pression stable élevée (entre 1018 et 1017 hPa)&nbsp;: défavorable à une volatilisation des polluants.</p></li></ul>",
    fieldInvestigationsDescription:
      "Les investigations de terrains menées par SOCOTEC Environnement sur le milieu gaz des sols ont consisté en la réalisation de XX piézairs / l’implantation de XX cannes gaz / la réalisation de XX points de prélèvement d’air sous dalle, le XX/XX/20XX. L’implantation des ouvrages a tenu compte de la localisation des zones sources de contaminations et de leur profondeur. Le matériel utilisé est indiqué :",
    fieldInvestigationsEquipment:
      "<ul><li><p>marteau percuteur portatif de type NORDMEYER avec carottier à fenêtre (l 1&nbsp;m et Ø 36/40 mm) ou sondeuse mécanique sur chenille de type SEDIDRILL / SOCOMAFOR ou APAGEO, équipée de tarières hélicoïdales emboîtables (longueur 1,50&nbsp;m, Ø 100 mm) utilisées en rotation&nbsp;;</p></li></ul>",
    piezairsInstallationDescription:
      "<p>Les piézairs ont été équipés comme suit&nbsp;:</p><ul><li><p>pose d’un tube PEHD (le PVC pouvant être à l’origine de phénomènes d’adsorption ou de désorption de composés volatils sur les tubages) de diamètre interne 25 mm, crépiné de -X à –X&nbsp;m et plein de -X à X&nbsp;m, et mise en place d’un bouchon de fond. L’espace annulaire (entre le tubage et les parois du sondage) a été rempli&nbsp;:</p><p>u&nbsp; Au niveau de la crépine&nbsp;: avec un massif filtrant constitué de sables et graviers chimiquement inertes (non calcaires)&nbsp;;</p><p>u&nbsp; Au niveau du tube plein&nbsp;: avec un coulis de bentonite pure (préparé avant introduction dans l’ouvrage) de –X à – X&nbsp;m. Du béton a été mis en place en surface pour assurer une bonne étanchéité.</p></li><li><p>Cimentation en tête de forage (sobranite et béton) et mise en place d’un bouchon papillon ou d’un bouchon vissé et d’un capot de protection étanche et bouche d’accès PEHD étanche et anti-vandalisme afin de limiter tout impact sur l’activité du site (accès ras le sol).</p></li></ul>",
    cuttingsInstallationDescription:
      "<p>Les cuttings ont été transportés par la société XX pour traitement ultérieur en centre adapté ou laissés sur site pour élimination par le maître d’ouvrage.</p><p>Les ouvrages de prélèvements sont des cannes gaz temporaires en acier mise en place à une profondeur de 1&nbsp;m. Ces cannes ont été mises en place à l’aide du matériel de sondage de SOCOTEC Environnement.</p><ul><li><p>Les cannes métalliques creuses ont un diamètre intérieur inférieur à 5 centimètres et une longueur supérieure à 1&nbsp;m, crépinées sur les 5 derniers centimètres.</p></li><li><p>Les cannes métalliques creuses ont un diamètre intérieur inférieur à 5 cm et une longueur supérieure à 1&nbsp;m avec une pointe perdue. Lors de sa mise en place, cette canne est enfoncée dans le sol jusqu’à une profondeur minimum de 1&nbsp;m. La canne, alors désolidarisée de la pointe, est remontée de 5 à 10 cm créant ainsi une chambre de prélèvement des gaz du sol.</p></li><li><p>Les cannes métalliques creuses ont un diamètre intérieur inférieur à 5 cm et une longueur supérieure à 1&nbsp;m avec une pointe rétractable entourée d’un manchon à l’extrémité de la canne. Lors de sa mise en place, cette canne est enfoncée dans le sol jusqu’à une profondeur minimum de 1&nbsp;m. Après enfoncement, la canne alors désolidarisée de la pointe, est remontée de 5 à 10 cm créant ainsi une chambre de prélèvement des gaz du sol. La pointe reste ancrée dans les sols, et un tubage crépiné est libéré lors de la remonté du manchon.</p></li></ul>",
    gasCaneCapillaryInstallationUninstallation:
      "<p>Un capillaire en PTFE (téflonⓇ&nbsp;; polytétrafluoroéthylène) ou tout autre matériau inerte) est ensuite introduit dans la canne gaz et l’espace annulaire de surface est obturé de façon étanche avec un matériau inerte. Ce capillaire est ensuite raccordé au dispositif de mesure ou de prélèvement.</p><p>Les cannes sont par la suite extraites à l’aide d’un extracteur hydraulique NORDMEYER (ZGM-9B/E Eco). X trou(s) pour la réalisation des prélèvements d’air sous dalle ont été réalisés à l’aide d’une perforateur de marque XX (diamètre du trou&nbsp;: 0,01&nbsp;m au minimum) à une profondeur de 0,10&nbsp;m à 0,20&nbsp;m sous la dalle, en créant une chambre d’échantillonnage juste en dessous de la dalle.</p>",
    piezairSectionDescription:
      "Chaque piézair a fait l’objet d’une coupe de sondage et d’un protocole de prélèvement indiquant notamment, la coupe lithologique avec la nature des formations géologiques rencontrées, les indices organoleptiques, la profondeur et la référence des échantillons. Les coupes des piézairs sont jointes en Annexe.",
    piezairSectionObservations:
      "<p>Les observations suivantes ont été effectuées&nbsp;:</p><ul><li><p></p></li></ul>",
    soilGasSupport: "des charbons actifs ou tout autre support adaptés",
    soilGasCompositePlace:
      " identifiés dans les sols et/ou les eaux souterraines du site",
    methodologyOfSoilGasSampling:
      "<ul><li><p>Délai de minimum 24h entre la réalisation du piézair permanent et le prélèvement,</p></li><li><p>Vérification de l’absence d’eau en fond d’ouvrage,</p></li><li><p>Mise en place de la ligne de prélèvement (tuyaux en PTFE et pompe Gilair +), sans le support, pour réalisation du test d’étanchéité avec mesure des concentrations en O2 et CO2, mesure de la température et de l’humidité, et purge de l’ouvrage.</p></li><li><p> A l’issue de la purge, installation du support entre le tuyau de prélèvement et la pompe et réalisation de l’échantillonnage,</p></li><li><p>Réalisation du prélèvement et établissement d’une fiche de prélèvement (les fiches de prélèvements sont présentées en annexe),</p></li><li><p>Contrôle du débit en début et fin de prélèvement.</p></li><li><p></p></li></ul>",
    supportUsedForSoilGasSampling: "(tube XX)",
    investigationsAdaptations:
      "<p>Aucun changement n’a été effectué par rapport aux investigations préconisées dans le cadre de la mission A130. Le plan d’investigations est donc celui présenté .</p><p>ou</p><p>Les investigations réalisées ont été adaptées par rapport au programme d’investigations prévisionnel compte tenu de la non-accessibilité au local transformateur, du refus systématique engendré au niveau de la dalle béton du local XXX, du constat de la présence de XXX…. Les changements suivants ont donc été effectués&nbsp;:</p><ul><li><p>Réalisation d’un sondage de sol complémentaire, nommé Sx, au droit de …</p></li><li><p>Non réalisation des sondages de sol Sxx et Sxx&nbsp;;</p></li><li><p>Profondeur du piézomètre PzX de XX&nbsp;m&nbsp;;</p></li><li><p>…</p></li></ul><p></p>",
    whiteTransportAndField: "BT1",
    soilGasesSamplingTimesAndFlowRatesTitle:
      "Définition des temps et débits de prélèvement",
    soilGasAnnexAnalyseModality:
      "Les analyses ont été effectuées selon les méthodes analytiques présentées en Annexe",
    soilGasInvestigationTitle:
      "Liste des ouvrages d’investigations sur les gaz des sols",
    weatherFromPreviousDaysTitle:
      "Données météorologiques des jours précédents",
  },
  en: {
    weatherStationLocation:
      "XX, located about X km to the XX of the study site",
    weatherSource: "Infoclimat",
    weatherSampleCampaignContext:
      "<ul><li><p>period of light precipitation (4.6, 0.2 and 2.6 mm the previous 3 days). This precipitation around the building can induce a potential increase in flows towards the air inside the building if the pollution is present below the building,</p></li><li><p>period of positive but low temperatures (temperatures between 3 and 9.9°C). These temperature conditions are considered neutral for the volatilization of soil gases (temperatures > 10°C favoring volatilization)&nbsp;;</p></li><li><p>period of high pressures (greater than 1013.25 hPa). These conditions of high atmospheric pressure are considered unfavorable to the volatilization of soil gases.</p><p>On XX/XX/20XX, the day of soil gas sampling, the following conditions were noted:< /p></li><li><p>light precipitation (about 0.2 mm during the day): no effect on the volatilization of pollutants;</p></li><li><p >stable low temperatures during the sampling time (between 8.4 and 10°C): neutral with respect to the volatilization of pollutants;</p></li><li><p>pressure stable high (between 1018 and 1017 hPa): unfavorable to volatilization of pollutants.</p></li></ul>",
    fieldInvestigationsDescription:
      "The field investigations carried out by SOCOTEC Environnement on the gas environment of the soil consisted of the construction of XX piezairs / the installation of XX gas rods / the construction of XX air sampling points under the slab, on XX/XX/20XX . The siting of the structures took into account the location of the source zones of contamination and their depth. The material used is indicated:",
    fieldInvestigationsEquipment:
      "<ul><li><p>portable impact hammer of the NORDMEYER type with window corer (l 1&nbsp;m and Ø 36/40 mm) or mechanical track drill of the SEDIDRILL / SOCOMAFOR or APAGEO type, equipped with interlocking helical augers ( length 1.50&nbsp;m, Ø 100 mm) used in rotation&nbsp;;</p></li></ul>",
    piezairsInstallationDescription:
      "<p>The piezairs were equipped as follows:</p><ul><li><p>installation of a HDPE tube (PVC can be the cause of adsorption or desorption of compounds on the casings) with an internal diameter of 25 mm, screened from -X to –X&nbsp;m and full from -X to X&nbsp;m, and installation of a bottom plug. The annular space (between the casing and the borehole walls) has been filled:</p><p>u&nbsp; At the level of the strainer: with a filtering mass made up of chemically inert sand and gravel (non-calcareous)&nbsp;;</p><p>u&nbsp; At the level of the full tube: with a pure bentonite grout (prepared before introduction into the structure) from –X to – X&nbsp;m. Concrete was placed on the surface to ensure a good seal.</p></li><li><p>Cementing at the head of the borehole (sobranite and concrete) and installation of a butterfly plug or a screwed cap and a waterproof protective cover and waterproof and vandal-proof HDPE access mouth to limit any impact on site activity (access at ground level).</p></li>< /ul>",
    cuttingsInstallationDescription:
      "<p>The cuttings were transported by the XX company for subsequent processing in a suitable center or left on site for disposal by the project owner.</p><p>The sampling structures are temporary steel gas canes placed in place at a depth of 1&nbsp;m. These rods were set up using SOCOTEC Environnement survey equipment.</p><ul><li><p>Hollow metal rods have an inside diameter of less than 5 centimeters and a length greater than 1&nbsp ;m, crimped over the last 5 centimeters.</p></li><li><p>Hollow metal canes have an inside diameter of less than 5 cm and a length of more than 1 m with a lost tip. When it is set up, this cane is driven into the ground to a minimum depth of 1&nbsp;m. The rod, then separated from the tip, is raised 5 to 10 cm, thus creating a chamber for sampling soil gases.</p></li><li><p>Hollow metal rods have a smaller inside diameter to 5 cm and a length greater than 1&nbsp;m with a retractable tip surrounded by a sleeve at the end of the rod. When it is set up, this cane is driven into the ground to a minimum depth of 1&nbsp;m. After sinking, the cane, then detached from the point, is raised 5 to 10 cm, thus creating a chamber for sampling gases from the ground. The point remains anchored in the ground, and a strained casing is released when the sleeve is raised.</p></li></ul>",
    gasCaneCapillaryInstallationUninstallation:
      "<p>A capillary made of PTFE (TeflonⓇ&nbsp;; polytetrafluoroethylene) or any other inert material) is then introduced into the gas cane and the surface annular space is sealed with an inert material. This capillary is then connected to the measuring or sampling device.</p><p>The canes are then extracted using a NORDMEYER hydraulic extractor (ZGM-9B/E Eco). X hole(s) for the air samples under the slab were taken using a brand XX perforator (hole diameter: 0.01&nbsp;m minimum) at a depth of 0.10&nbsp;m to 0.20&nbsp; ;m below the slab, creating a sample chamber just below the slab.</p>",
    gasCaneCapillary: "(made of PTFE (Teflon®; polytetrafluoroethylene)",
    piezairSectionDescription:
      "Each piezair was the subject of a sounding section and a sampling protocol indicating in particular the lithological section with the nature of the geological formations encountered, the organoleptic indices, the depth and the reference of the samples. The sections of the piezairs are attached in the Appendix.",
    piezairSectionObservations:
      "<p>The following observations were made:</p><ul><li><p></p></li></ul>",
    soilGasSupport: "activated carbons or any other suitable medium",
    soilGasCompositePlace:
      " identified in soils and/or groundwater at the site",
    methodologyOfSoilGasSampling:
      "<ul><li><p>Minimum 24-hour period between the creation of the permanent piezair and the sampling,</p></li><li><p>Verification of the absence of water at the bottom of the structure ,</p></li><li><p>Installation of the sampling line (PTFE pipes and Gilair + pump), without the support, for carrying out the tightness test with measurement of O2 concentrations and CO2, measurement of temperature and humidity, and purging of the structure.</p></li><li><p> After purging, installation of the support between the sampling pipe and the pump and performing the sampling,</p></li><li><p>Taking the sample and drawing up a sampling sheet (the sampling sheets are presented in the appendix),</p> </li><li><p>Flow control at start and end of sampling.</p></li><li><p></p></li></ul>",
    supportUsedForSoilGasSampling: "(tube XX)",
    whiteTransportAndField: "BT1",
    soilGasesSamplingTimesAndFlowRatesTitle:
      "Definition of sampling times and flow rates",
    soilGasAnnexAnalyseModality:
      "The analyzes were carried out according to the analytical methods presented in the Appendix",
    soilGasInvestigationTitle: "Definition of sampling times and flow rates",
    weatherFromPreviousDaysTitle: "Definition of sampling times and flow rates",
  },
};
