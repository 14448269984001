export const surveyMessages = {
  en: {
    SOIL: "Soil",
    UNDERGROUND_WATERS: "Underground waters sampling",
    AMBIENT_AIR: "Ambient air sampling",
    SOIL_GAS: "Soil gas",
    EXCAVATED_SOIL: "Excavated soil",
    COMPOSITE: "Composite",
    FILLING: "Filling",
    ON_SITE_WASTE_MANAGEMENT: "On-Site waste management",
    OFF_SITE_WASTE_MANAGEMENT: "Off-site waste management",
    CUTTINGS: "Cuttings",
    COLD_MIX: "Cold mix",
    CONCRETE: "Concrete",
    SAND: "Sand",
    UNIT: "Unit",
    BY_JUDGMENT: "By judgment",
    PIEZOMETER: "Piezometer",
    FORAGE: "Forage",
    EXISTING_WORK: "Existing work",
    PIEZAIR: "Piezair",
    VAPOR_PIN: "Air under slab/Vapor pin",
    GAS_CANE: "Gas cane",
    DRY_LIGHTLY_CLOUDED: "Dry and lightly clouded",
    DRY_CLOUDED: "Dry and clouded",
    LIGHT_RAIN: "Light rain",
    HEAVY_RAIN: "Heavy rain",
    SNOWY: "Snowy",
    FREEZING: "Freezing",
    DUSK_CONDITIONS: "Dusk conditions",
    BENTONITE: "Bentonite",
    OREGONITE: "Oregonite",
    UPSTREAM: "Upstream",
    DOWNSTREAM: "Downstream",
    UPSTREAM_LATERAL: "Upstream lateral",
    DOWNSTREAM_LATERAL: "Downstream lateral",
    LATERAL: "LATERAL",
    UNKNOWN: "Unknown",
    ON_PILE: "On pile",
    ON_WALLS_BOTTOM_OF_EXCAVATIONS: "On walls and bottom of excavations",
  },
  fr: {
    SOIL: "Sol",
    UNDERGROUND_WATERS: "Eaux souterraines",
    AMBIENT_AIR: "Air ambiant",
    SOIL_GAS: "Gaz des sols",
    EXCAVATED_SOIL: "Terres excavées",
    COMPOSITE: "Composite",
    FILLING: "Rebouchage",
    ON_SITE_WASTE_MANAGEMENT: "Gestion des déchets sur site",
    OFF_SITE_WASTE_MANAGEMENT: "Gestion des déchets hors site",
    CUTTINGS: "Cuttings",
    COLD_MIX: "Enrobé à froid",
    CONCRETE: "Béton",
    SAND: "Sable",
    UNIT: "Unitaire",
    BY_JUDGMENT: "Par jugement",
    PIEZOMETER: "Piézometre",
    FORAGE: "Forage",
    EXISTING_WORK: "Ouvrage existant",
    PIEZAIR: "Piézair",
    VAPOR_PIN: "Air sous dalle/Vapor pin",
    GAS_CANE: "Canne gaz",
    DRY_LIGHTLY_CLOUDED: "Temps sec faiblement nuageux",
    DRY_CLOUDED: "Temps sec fortement nuageux",
    LIGHT_RAIN: "Pluie fine",
    HEAVY_RAIN: "Pluie forte/Orage",
    SNOWY: "Neige",
    FREEZING: "Gel",
    DUSK_CONDITIONS: "Conditions crépusculaires",
    BENTONITE: "Bentonite",
    OREGONITE: "Orégonite",
    UPSTREAM: "Amont",
    DOWNSTREAM: "Aval",
    UPSTREAM_LATERAL: "Amont latéral",
    DOWNSTREAM_LATERAL: "Aval latéral",
    LATERAL: "Latéral",
    UNKNOWN: "Inconnue",
    ON_PILE: "Sur tas",
    ON_WALLS_BOTTOM_OF_EXCAVATIONS: "En parois et fond de fouilles",
  },
};
