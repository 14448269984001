export const inverstigatedEnvsMessages = {
  en: {
    BOTTLE: "Glass bottle 375 ml",
    PLASTIC_POT: "Plastic pot 2kg",
    METHENOL_KIT: "Methanol kit",
    METAL_SOLUBLE_CHROME_VI_P01_PE_100:
      "Metal soluble - Chrome VI - P01 - PE 100 mL",
    FLUORIDE_DBO5_P04_PE_250: "Fluoride - DBO5 - P04 - PE 250 mL",
    METALS_EXCEPT_HG_P10_PE_60_HNO3:
      "Metals (except Hg) - P10 - PE 60 mL / HNO3 (>65%) 0.3 mL",
    COD_P13_PE_250_H2SO4: "COD - P13 - PE 250 mL / H2SO4 (>59%) 2.5 mL",
    CYANIDES_P15_PE_125_NAOH: "Cyanides - P15 - PE 125 mL / NaOH (1N) 1.3 mL",
    POLAR_SOLVENTS_V01_GLASS_120: "Polar solvents - V01 - Glass 120 mL",
    CHROME_III_RESERVE_V02_GLASS_250:
      "Chrome III - Reserve - V02 - Glass 250 mL",
    MERCURY_V07_GLASS_120_HCL:
      "Mercury - V07 - Glass 120 mL / HCl (>36%) 0.6 mL",
    HCT_BTEX_COHV_TPH_PHENOL_INDEX_V08_GLASS_80:
      "HCT - BTEX - COHV - TPH - Phenol Index - V08 - Glass 80 mL (x2) / H2SO4 (>59%) 0.4 mL",
    AOX_V11_GLASS_250_HNO3: "AOX - V11 - Glass 250 mL / HNO3 (>65%) 2.5 mL",
    PAH_PCB_POC_POP_V13_GLASS_100: "PAH - PCB - POC/POP - V13 - Glass 100 mL",
    BOTTLE_375: "Glass bottle 375 ml",
  },
  fr: {
    BOTTLE: "Flacon verre 375 ml",
    PLASTIC_POT: "Pot plastique 2kg",
    METHENOL_KIT: "Kit méthanol",
    METAL_SOLUBLE_CHROME_VI_P01_PE_100:
      "Métaux solubles - Chrome VI - P01 - PE 100 mL",
    FLUORIDE_DBO5_P04_PE_250: "Fluorure - DBO5 - P04 - PE 250 mL",
    METALS_EXCEPT_HG_P10_PE_60_HNO3:
      "Métaux (sf Hg) - P10 - PE 60 mL / HNO3 (>65%) 0,3 mL",
    COD_P13_PE_250_H2SO4: "DCO - P13 - PE 250 mL / H2SO4 (>59%) 2,5 mL",
    CYANIDES_P15_PE_125_NAOH: "Cyanures - P15 - PE 125 mL / NaOH (1N)1,3 mL",
    POLAR_SOLVENTS_V01_GLASS_120: "Solvants polaires - V01 - Verre 120 mL",
    CHROME_III_RESERVE_V02_GLASS_250:
      "Chrome III - Réserve - V02 - Verre 250 mL",
    MERCURY_V07_GLASS_120_HCL:
      "Mercure - V07 - Verre 120 mL / HCl (>36%) 0,6 mL",
    HCT_BTEX_COHV_TPH_PHENOL_INDEX_V08_GLASS_80:
      "HCT - BTEX - COHV - TPH - Indice Phénol - V08 - Verre 80 mL (x2) / H2SO4 (>59%) 0,4 mL",
    AOX_V11_GLASS_250_HNO3: "AOX - V11 - Verre 250 mL / HNO3 (>65%) 2,5 mL",
    PAH_PCB_POC_POP_V13_GLASS_100: "HAP - PCB - POC/POP - V13 - Verre 100 mL",
    BOTTLE_375: "Flacon verre 375 ml",
  },
};
