export const assessmentOfUncertainties = {
  fr: {
    assessmentOfUncertaintiesIntroduction:
      "<p>Comme toute étude, ce diagnostic est susceptible de présenter des incertitudes inhérentes aux nombreux facteurs intervenants dans sa réalisation (informations collectées, investigations et mesures réalisées, hypothèses prises en compte …).</p><p>Ces dernières font l’objet d’une évaluation qualitative dans le tableau ci-après, recensant pour les causes de ces incertitudes et les moyens mis en œuvre pour les limiter.</p>",
    meansUsedSiteVisite: "L’ensemble des locaux accessibles a été visité.",
    meansUsedHistoricalStudy:
      "Ce diagnostic a été réalisé sur la base des informations recueillies par nos soins. Une attention particulière a été portée sur l’exhaustivité des sources d’informations. Lorsqu’il a été possible de le faire, les différentes sources ont été recoupées afin de valider les informations. Certaines informations ont pu ne pas être transmises à SOCOTEC Environnement par les interlocuteurs, volontairement ou involontairement.",
    meansUsedSurveyImplementation:
      "Les sondages ont été implantés pour les sols à proximité des sources de pollution identifiées / selon un maillage permettant d’assurer un maillage homogène du site." +
      "Pour les piézomètres, ils ont été implantés en fonction du sens d’écoulement présumé." +
      "Plus le nombre de sondages et de prélèvements est important, plus la précision des investigations est améliorée." +
      "Les investigations sont nécessairement limitées et proportionnées aux enjeux. En première approche, les investigations réalisées sont pertinentes et représentatives." +
      "Les prélèvements ont été réalisés selon les normes existantes.",
    meansUsedPackaging:
      "Conditionnement en flaconnage adapté (flacon étanche en verre brun ou autre) selon les milieux prélevés, conservation à l’obscurité dans une glacière avec blocs réfrigérants. Les échantillons sont envoyés au laboratoire le jour même de leur" +
      "prélèvement ou le lendemain.",
    meansUsedAnalyticalMethods:
      "Les analyses ont été réalisées dans un laboratoire accrédité. Les méthodes choisies sont préférentiellement des méthodes normées internationales (ISO ou EN).",
    meansUsedAnalyticalProgramme:
      "Le programme analytique a été élaboré sur la base des informations recueillies, de notre retour d’expérience et des observations de terrain. Le nombre d’analyse et le choix des paramètres restent proportionnés et adaptés aux zones et milieux investigués",
    meansUsedConceptualDiagram:
      "Toute modification du projet d’aménagement ou de l’usage du site est susceptible d’entrainer une modification du schéma conceptuel, et donc des recommandations formulées en conclusion.",
  },
  en: {
    assessmentOfUncertaintiesIntroduction:
      "<p>Like any study, this diagnosis is likely to present uncertainties inherent to the many factors involved in its realization (information collected, investigations and measurements carried out, assumptions taken into account ...).</p><p>The latter are the subject of a qualitative assessment in the table below, listing for the causes of these uncertainties and the means implemented to limit them.</p>",
    meansUsedSiteVisite: "All accessible premises were visited.",
    meansUsedHistoricalStudy:
      "This diagnosis was carried out on the basis of information collected by us. Particular attention was paid to the completeness of the information sources. Where possible, the various sources were cross-checked in order to validate the information. Some information may not have been transmitted to SOCOTEC Environnement by the interlocutors, either voluntarily or involuntarily",
    meansUsedSurveyImplementation:
      "The test pits were installed in the vicinity of the identified sources of pollution / in a grid pattern to ensure a homogeneous grid of the site." +
      "For the piezometers, they were installed according to the presumed direction of flow." +
      "The greater the number of boreholes and samples taken, the greater the accuracy of the investigations." +
      "Investigations are necessarily limited and proportionate to the issues at stake. At first glance, the investigations carried out are relevant and representative." +
      "The samples were taken in accordance with existing standards",
    meansUsedPackaging:
      "Packaged in suitable containers (brown glass or other airtight bottles) according to the media collected, stored in the dark in a cool box with ice packs. Samples are sent to the laboratory the same day they are collected sampling or the following day.",
    meansUsedAnalyticalMethods:
      "The analyses were carried out in an accredited laboratory. The methods chosen are preferably international standard methods (ISO or EN)",
    meansUsedAnalyticalProgramme:
      "The analytical programme was developed on the basis of the information collected, our feedback and field observations. The number of analyses and the choice of parameters remain proportionate and adapted to the areas and environments investigated",
    meansUsedConceptualDiagram:
      "Any change in the development project or in the use of the site is likely to lead to a change in the conceptual scheme, and therefore in the recommendations made in the conclusion",
  },
};
