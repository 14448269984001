export const excavatedGroundDefaultValues = {
  fr: {
    excavatedGroundDrillingDetails:
      "Les investigations de terrains menées par SOCOTEC Environnement sur le milieu sol ont consisté en la réalisation de XX sondages au droit des XX lots de terres excavées du site ou XX sondages jusqu’à une profondeur maximale de XX m.",
    excavatedGroundStrategySamples:
      "<ul><li>Tarière manuelle (de type EDELMAN) ;</li><li>Marteau percuteur portatif de type NORDMEYER avec carottier à fenêtre (l 1 m et Ø 36/40 mm) ;</li><li>Sondeuse mécanique sur chenille de type SEDIDRILL SD 80 ou autre si sous-traité, équipée de tarières hélicoïdales emboîtables (longueur 1,50 m et Ø 63 mm) ;</li><li>Sondeuse mécanique sur chenille SEDIDRILL 200 RPVL ou SOCOMAFOR ou APAGEO ou autre si sous-traité, avec tarières hélicoïdales emboîtables (longueur 1,50 m, Ø 80 mm) (rotation) ou carottier échantillonneur à fenêtre (longueur 1 m, Ø 54/47 mm) (percussion) ou carottier échantillonneur à gaine (longueur 1 m, Ø 57/43 mm) (percussion) pour prélèvement sous gaine ;</li><li>Pelle mécanique.</li></ul>",
    removingExcavatedGroundProtocol:
      "<p>Le protocole de prélèvement de terres excavées en tas est défini de la manière suivante :</p><ul><li>Les stocks de terres sont constitués de manière homogène à partir de résultats analytiques ou les stocks de terres sont disposés en tertres ;</li><li>Les stocks de terres représentent X m3 en place, sur cette base, un échantillon composite (réalisé à partir de 10 prélèvements élémentaires homogénéisé par quartage) et un échantillon unitaire (pour recherche de composés volatils) ont été réalisés par lot de 250 m3 à maxima.</li></ul>",
    postSamplingTreatment:
      "<p>Lorsque les prélèvements ont été effectués, les sondages ont été rebouchés avec les cuttings non prélevés et les revêtements de surface (béton, enrobés) ont été reformés par un bouchon en ciment / par de l’enrobé à froid. Les cuttings excédentaires ont été transportés vers notre laboratoire pour traitement ultérieur en centre adapté ou laissés sur site pour prise en charge par le Maître d’ouvrage.</p><p>La méthodologie utilisée pour la réalisation des prélèvements des terres à excavées était la suivante : maillage 10 m x 10 m, maillage aléatoire...</p>",
    excavatedGroundSamplesDetails:
      "<p>la constitution de XX échantillons de sols, prélevés par tranche de 1 m ou par horizon organoleptiquement différent, prélèvement systématique stratifié par passe d’environ un mètre sur toute la hauteur des sondages ou par horizon homogène.</p>",
    excavatedGroundAnalysisDoneBy:
      "<p>Parmi les X échantillons prélevés, X ont été sélectionnés et envoyés au laboratoire EUROFINS accrédité par le COFRAC. Les X autres ont également été envoyés au laboratoire et mis en réserve.</p>",
    excavatedGroundObservations:
      "<p>Chaque point de sondage a fait l’objet d’une fiche de sondage et de prélèvement indiquant notamment, la coupe lithologique avec la nature des formations géologiques rencontrées, les indices organoleptiques, la profondeur et la référence des échantillons. Ces fiches sont jointes en Annexe.</p><p>Les éléments suivants ont été observés :</p>",
    excavatedGroundAnalysisProgramTitle: "Programmes d'analyses",
    excavatedGroundConditioning:
      "<p>Chaque échantillon a été immédiatement conditionné dans un pot en plastique blanc opaque de XXX fourni par le laboratoire. Chaque pot est étiqueté puis conservé à basse température et à l’obscurité dans une glacière, jusqu’à l’expédition au laboratoire pour réalisation des analyses. La date de transport des échantillons correspond à l’intervalle entre la date de prélèvement et la date de réception des échantillons au laboratoire d’analyses. Ces dates sont mentionnées dans les rapports d’analyses du laboratoire présents en pièce-jointe de ce rapport. Les prélèvements de terres excavées ou à excavées ont été effectués et conditionnés conformément aux normes de la série NF ISO 18400.</p>",
  },
  en: {
    excavatedGroundDrillingDetails:
      "XX borings at XX lots of excavated soil on the site or XX borings to a maximum depth of XX m.",
    excavatedGroundStrategySamples:
      "<ul><li>Manual auger (EDELMAN type);</li><li>NORDMEYER type portable hammer drill with window core barrel (l 1 m and Ø 36/40 mm);</li><li> SEDIDRILL SD 80 type mechanical crawler drill or other if subcontracted, equipped with interlocking helical augers (length 1.50 m and Ø 63 mm);</li><li>SEDIDRILL 200 RPVL or SOCOMAFOR or APAGEO or other if subcontracted, with interlocking helical augers (length 1.50 m, Ø 80 mm) (rotation) or window sampler (length 1 m, Ø 54/47 mm) (percussion) or sheath sampler (length 1 m, Ø 57/43 mm) (percussion) for sampling under the sheath;</li><li>Mechanical shovel.</li></ul>",
    removingExcavatedGroundProtocol:
      "<p>The sampling protocol for excavated soil in heaps is defined as follows:</p><ul><li>Soil stocks are constituted in a homogeneous way from analytical results or soil stocks are arranged in mounds;</li><li> Soil stocks represent X m3 in place, on this basis, a composite sample (made from 10 elementary samples homogenized by quartering) and a unit sample (for volatile compounds research) have been made by batch of 250 m3 at maximum.</li></ul>",
    postSamplingTreatment:
      "<p>When the samples were taken, the boreholes were filled in with the unsampled cuttings and the surface coatings (concrete, asphalt) were reformed with a cement plug / with cold asphalt. Excess cuttings were transported to our laboratory for further processing in a suitable center or left on site for management by the Client.</p><p>The methodology used to take samples from the excavated soil was the following: mesh 10 m x 10 m, random mesh...</p>",
    excavatedGroundSamplesDetails:
      "<p>the constitution of XX soil samples, taken per 1 m section or per organoleptically different horizon, systematically stratified sampling by pass of approximately one meter on all the height of the soundings or by homogeneous horizon.</p>",
    excavatedGroundAnalysisDoneBy:
      "<p>Of the X samples taken, X were selected and sent to the COFRAC accredited EUROFINS laboratory. The remaining X were also sent to the laboratory and placed in reserve.</p>",
    excavatedGroundObservations:
      "<p>Each survey point was the subject of a survey and sampling sheet indicating in particular the lithological section with the nature of the geological formations encountered, the organoleptic indices, the depth and the reference of the samples. These sheets are attached in the Appendix.</p><p>The following elements were observed:</p>",
    excavatedGroundAnalysisProgramTitle: "Analysis program",
  },
};
