export const undergroundWaterDefaultValues = {
  fr: {
    worksImplantation:
      "un en amont et deux en aval afin de connaître la qualité des flux entrants et sortants.",
    usedMaterial:
      "<p>Le matériel utilisé pour la pose des piézomètres est précisé ci-avant dans le paragraphe XX ou ci-après&nbsp;:</p><ul><li><p>Les piézomètres ont été posés conformément à la norme NF X 31-614 à l’aide d’une sondeuse mécanique sur chenille SEDIDRILL 200 RPVL / APAGEO / SOCOMAFOR ou matériel sous-traité, qui pourra être équipée, de tarières hélicoïdales emboîtables (longueur 1,50&nbsp;m, Ø 100 mm) (rotation, d’un marteau fond de trou, d’un taillant R32 Ø 89 mm à bouton rond.</p></li></ul><p>Chaque ouvrage a été équipé d’un tubage PVC ou PEHD de XX/XX mm de diamètre, crépiné sur toute la hauteur d’eau y compris un mètre au-dessus du niveau piézométrique. Mise en place d’un massif filtrant sur toute la hauteur crépinée, puis d’une couche de bentonite jusqu’en tête d’ouvrage (de 0,50&nbsp;m au minimum). Chaque ouvrage sera scellé en surface par du ciment et équipé d’une protection métallique en tête.</p>",
    cuttingStatus:
      "transportés par la société XX pour traitement ultérieur ou laissés sur site pour élimination par le maître d’ouvrage.",
    undergroundWaterSamplesCollection:
      "<p>Les prélèvements d’eaux souterraines ont été effectués au droit de l’ensemble des ouvrages du site conformément à la norme NF X 31-615.</p><p>Les piézomètres ont fait l’objet d’une purge avant prélèvement, selon la démarche suivante :</p><ul><li>relevé du niveau piézométrique avant purge</li><li>contrôle du surnageant (visuel) par préleveur manuel jetable en PEHD ou un échantillonneur polypropylène stérile à usage unique,</li><li>ancrage de la pompe au fond de la colonne d’eau,</li><li>purge de l’ouvrage (attente d’un niveau de moindre turbidité, stabilisation des paramètres physico- chimique ou à défaut pompage minimum de 3 fois le volume de l’ouvrage),</li><li>contrôle du niveau piézométrique après purge,</li><li>observation des caractéristiques organoleptiques (couleur, odeur…).</li></ul><p>Les eaux de purge ont été filtrées sur charbon actif et rejetées au réseau d'assainissement le plus proche ou autre.</p><p>L'ordre de prélèvement du positionnement hydraulique des ouvrages ou des ouvrages les moins vers les plus impactésn soit dans l'ordre</p><p>Dans ce cadre, le matériel de prélèvement et ses dispositifs associés n'ont pas fait l'objet d'un nettoyage entre ouvrages prélévés, la purge des ouvrages étant considérée comme suffisante.</p><p>Les prélèvements ont été effectués d'une pompe péristatique ou grundfoss, ou un échantilloneur polypropylène stérile à usage unique.</p>",
    undergroundWaterFieldMeasurements:
      "<p>Les niveaux piézomètriques ont été mesurés sur site par nos soins pour chaque piézomètre au moyen d'une sonde à interface SILEX/Solinst</p><p>Les altitudes et profondeurs d'eau ont été mesurées par rapport au sommet du tubage PVC/PEHD des ouvrages ou autre</p><p>Les caractéristiques organoleptiques des échantillons prélevées ont été relevées et des mesures ponctuelles sur le site pour les eaux ont été réalisées à l'aide d'un boitier multi-paramètres WTW 340i équipé d'une sonde WTW TetraCon 325 et WTW Sen Tix 41 préalablement étalonnée par nos soins(température, pH et conductivité)</p><p>Les observations suivantes ont été effectuées :</p><p>L'ensemble de ces données est consigné dans les protocoles de prélèvement joints en Annexe.</p><p>Les échantillons destinés à l'analyse des métaux ont été filtrés préalablement à leur conditionnement.</p>",
    undergroundWaterAnalyticalProgramTitle: "Programmes analytiques",
    undergroundWaterInvestigationsStructureTitle: "Ouvrages d'investigation",
    undergroundWaterConditioning:
      "<p>L’ensemble des échantillons a été conditionné en flaconnage adapté fourni par le laboratoire d’analyses (flacons en matériaux adaptés, verre ou PET, avec éventuels réactifs de conservation selon les paramètres à analyser) et conservé au frais en caisson isotherme à faible température (environ 4°C) jusqu’à leur expédition pour analyses au laboratoire par transporteur express, conformément à la norme NF EN ISO 5667-3 ( Qualité de l’eau - Echantillonnage - Partie 3 : Guide général pour la conservation et la manipulation des échantillons).</p>",
  },
  en: {
    worksImplantation:
      "one upstream and two downstream in order to know the quality of the incoming and outgoing flows.",
    usedMaterial:
      "<p>The equipment used for the installation of the piezometers is specified above in paragraph XX or below:</p><ul><li><p>The piezometers were installed in accordance with the NF X 31-614 standard using a SEDIDRILL 200 RPVL / APAGEO / SOCOMAFOR or subcontracted equipment, which could be equipped with interlocking helical augers (length 1.50 m, Ø 100 mm) (rotation, a down-the-hole hammer, a R32 Ø 89 mm round button bit.</p></li></ul><p>The structure was equipped with a PVC or HDPE casing of XX/XX mm diameter, screened over the entire water height including one meter above the piezometric level. A filter bed is placed over the entire height of the well, followed by a layer of bentonite up to the head of the structure (minimum 0.50 m). The structure will be sealed on the surface with cement and equipped with a metal protection at the top.</p>",
    cuttingStatus:
      "transported by company XX for further treatment or left on site for disposal by the project owner",
    undergroundWaterSamplesCollection:
      "<p> Les prélèvements d’eaux souterraines ont été effectués au droit de l’ensemble des ouvrages du site conformément à la norme NF X 31-615.</p><p> Les piézomètres ont fait l’objet d’une purge avant prélèvement, selon la démarche suivante : </p><ul><li>relevé du niveau piézométrique avant purge</li><li>contrôle du surnageant (visuel) par préleveur manuel jetable en PEHD ou un échantillonneur polypropylène stérile à usage unique,</li><li>ancrage de la pompe au fond de la colonne d’eau,</li><li>purge de l’ouvrage (attente d’un niveau de moindre turbidité, stabilisation des paramètres physico- chimique ou à défaut pompage minimum de 3 fois le volume de l’ouvrage),</li><li>contrôle du niveau piézométrique après purge,</li><li>observation des caractéristiques organoleptiques (couleur, odeur…).</li></ul><p>Les eaux de purge ont été filtrées sur charbon actif et rejetées au réseau d'assainissement le plus proche ou autre.</p><p>L'ordre de prélèvement du positionnement hydraulique des ouvrages ou des ouvrages les moins vers les plus impactésn soit dans l'ordre</p><p>Dans ce cadre, le matériel de prélèvement et ses dispositifs associés n'ont pas fait l'objet d'un nettoyage entre ouvrages prélévés, la purge des ouvrages étant considérée comme suffisante.</p><p>Les prélèvements ont été effectués d'une pompe péristatique ou grundfoss, ou un échantilloneur polypropylène stérile à usage unique.</p>",
    undergroundWaterFieldMeasurements:
      "<p>The piezometric levels were measured on site by us for each piezometer using a SILEX/Solinst interface probe</p><p>The altitudes and water depths were measured in relation to the summit of the PVC/HDPE casing of the structures or other</p><p>The organoleptic characteristics of the samples taken were noted and specific measurements on the site for the water were carried out using a WTW multi-parameter box 340i equipped with a WTW TetraCon 325 and WTW Sen Tix 41 probe previously calibrated by us (temperature, pH and conductivity)</p><p>The following observations were made:</p><p>The whole of these data is recorded in the sampling protocols attached in the Appendix.</p><p>The samples intended for the analysis of metals were filtered before being packaged.</p>",
    undergroundWaterAnalyticalProgramTitle: "Analytical programs",
    undergroundWaterInvestigationsStructureTitle: "Investigation works",
  },
};
