export const missionPresentationDefaultValues = {
  fr: {
    missionContext:
      "<p>Cette mission est réalisée dans le cadre de XXX (vente, suite à un accident, d’un permis de construire, d’un arrêté préfectoral, d’une cessation d’activité…).</p><p>Le projet envisage la réalisation d’un programme immobilier dont le plan masse est présenté ci-après. </p>",
    referenceDocuments:
      "<p>Cette étude se base sur la proposition commerciale N°--, établie par SOCOTEC Environnement le XX/XX/XXXX, ayant reçu votre accord du XX/XX/XXXX.</p><p>Elle prend en compte les documents de référence et les études antérieures suivantes :</p><ul><li>Rapport SOCOTEC NOM du XX/XX/XX, référencé REF </li></ul><p>ou</p><p>Aucune étude antérieure ou document de référence ne nous a été communiqué.</p>",
    localisationPlanTitle: "Plan de localisation du site",
    cadastralPlanTitle: "Extrait du plan cadastral",
  },
  en: {
    missionContext:
      "<p>This mission is carried out within the framework of XXX (sale, following an accident, a building permit, a prefectural order, a cessation of activity...).</p> <p>The project contemplates the realization of a real estate program whose mass plan is presented below. </p>",
    referenceDocuments:
      "<p>This study is based on the commercial proposal N°--, established by SOCOTEC Environnement on XX/XX/XXXX, having received your agreement of XX/XX/XXXX.</p><p>It takes into account the following reference documents and previous studies:</p><ul><li>SOCOTEC report NAME of XX/XX/XX, referenced REF </li></ul><p>or</p><p>No previous studies or reference documents were provided to us.</p>",
    localisationPlanTitle: "Site location map",
    cadastralPlanTitle: "Extract from the cadastral plan",
  },
};
