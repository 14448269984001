export const conceptMapUpdateDefaultValues = {
  fr: {
    conceptMapUpdateIntroduction:
      "<p>Le schéma conceptuel du site, détaillé ci-après, permet d’évaluer les impacts potentiels du site vis-à-vis des usagers actuels / des futurs usagers en considérant son aménagement actuel/futur.</p>",
    assumptionsConsidered:
      "<ul><li><p>Un recouvrement superficiel des sols du site (dalle béton, enrobé bitumineux, terres végétales saines) bloquant tout transfert direct entre les sols en place et les futurs usagers du site&nbsp;;</p></li><li><p>Un apport de XX cm de terres végétales saines au droit des zones de sols nus du site&nbsp;;</p></li><li><p>Une interdiction de cultures de fruits et légumes sur site&nbsp;;</p></li><li><p>Une interdiction de puits et captages d’eau souterraine au droit du site&nbsp;;</p></li><li><p>Une mise en œuvre des canalisations AEP en fonte ou placées dans des tranchées remblayées à l’aide de terre saine&nbsp;;</p></li><li><p> …</p></li></ul>",
    sourcesOfContamination:
      "<ul><li><p>Les sols contaminés par des hydrocarbures…&nbsp;;</p></li><li><p>Les eaux souterraines, contaminées par des métaux lourds (Pb, Zn…)</p></li><li><p>…</p></li></ul>",
    humanTargetIdentification:
      "<p>L'usage considéré est l'usage actuel/futur de type résidentiel / tertiaire / artisanal / commercial / industriel / agricole.</p><p>Dans ce cadre, les usagers du site, cibles susceptibles d'être exposées sont les résidents adultes et enfants/les travailleurs.</p>",
    analysisInterpretationIntroduction:
      "<p>L’interprétation des résultats est réalisée en comparaison aux valeurs de références présentée précédemment.</p><p>Pour chaque milieu vérifier cohérence des résultats / observations de terrain et résultats attendus.</p>",
    analysisInterpretation:
      "Interprétation à présenter de manière cohérente, par typologie de paramètres recherchés ou par zone d’investigations, selon le contexte",
    samplesValidity:
      "<p>Préalablement à l’interprétation des résultats sur l’air ambiant, la validité des prélèvements et des mesures doit être vérifiée.</p><ol><li><p>Variation du débit au cours du prélévement :</p><p>De principe, une variation trop importante du débit rend le résultat non représentatif du prélèvement effectué, pouvant indiquer une perte de charge lors du prélèvement. Pour la validité des prélèvements, on considère que cette variation du débit au cours de la phase de prélèvement ne doit pas excéder 10 % au maximum (5 % dans l’idéal).</p><p>On constate ici des variations de débit très faibles entre le début et la fin de prélèvement, inférieures à 5 %. Les résultats sont donc validés et considérés comme représentatifs.</p></li><li><p>Coefficient de désorption des supports</p><p>Pour la validité des mesures, le résultat en zone de mesure est représentatif du prélèvement lorsque l’on obtient moins de 5 % de substances détectées en zone de contrôle par rapport à celle de la zone de mesure.</p><p>Les résultats obtenus ici montrent l’absence des substances recherchées dans les zones de contrôle pour les supports analysés, bien que certaines aient été quantifiées en zones de mesure. Les résultats sont donc validés et considérés comme représentatifs.</p></li><li><p>Validation des supports de prélèvements et de la méthodologie</p><p>Aucune des substances recherchées n’a été quantifiée sur les blancs de terrain BT1 analysés. Les supports de prélèvement de ce lot et la façon de prélever n’ont donc pas eu d’influence sur les résultats obtenus.</p></li></ol>",
    exposureMediaAndTheirUses:
      "<p>L’hypothèse d’un recouvrement de l’ensemble des sols étant prise, le milieu sol n’est pas considéré comme milieu d’exposition directe pour l’homme. En effet, les aménagements prévus suppriment toute possibilité de contact cutané avec les sols superficiels pollués, d’ingestion directe de sols superficiels pollués et d’inhalation de poussières de sols pollués.</p><p>L’hypothèse d’une interdiction de jardins potagers ou arbres fruitiers étant prise en considération, l’exposition liée à l’ingestion de végétaux cultivés sur sols pollués n’est également pas prise en compte.</p><p>Le milieu air est susceptible d’être impacté par les substances polluantes volatiles présentes dans les sols.</p><p>L’interdiction d’usage des eaux souterraines au droit site étant considérée, les expositions liées à l’utilisation de ces eaux au droit du site ne sont pas prises en compte. En revanche, l’utilisation des eaux souterraines par des particuliers pour l’arrosage, l’irrigation et le remplissage de piscine, en aval du site, étant potentiellement effectuée ce milieu est donc considéré comme milieu d’exposition hors site.</p><p>Les eaux superficielles sont utilisées pour des activités de pêche en aval du site, ce milieu est également pris en considération hors site dans le présent schéma.</p><p>Dans ce cadre, les milieux d’exposition sur site sont limités à&nbsp;:</p><ul><li><p>l’air ambiant intérieur des futurs bâtiments et l’air ambiant extérieur du site,</p></li><li><p>les eaux souterraines,</p></li><li><p>les eaux superficielles.</p></li></ul>",
    soilContaminantTransfer:
      "<p>Les contaminants présents dans les sols sont susceptibles de se transférer vers :</p><ul><li><p>l’air ambiant intérieur ou extérieur, par volatilisation depuis la source de pollution et transfert sous forme gazeuse,</p></li><li><p>les eaux souterraines par infiltration.</p></li></ul>",
    undergroundWatersContaminantTransfer:
      "<p>Les contaminants potentiellement présents dans les eaux souterraines sont susceptibles de se transférer vers :</p><ul><li><p>l’air ambiant intérieur ou extérieur, par volatilisation depuis les eaux et transfert sous forme gazeuse,</p></li><li><p>les eaux superficielles du fait des relations existantes entre rivière et nappe.</p></li></ul>",
    contaminantNotTransfer:
      "<p>En revanche, les voies de transfert suivantes ne sont pas prises en compte :</p><ul><li><p>l’envol de poussières à partir des sols superficiels du fait de la mise en place d’un recouvrement des sols au droit du site, et de la présence au droit de la parcelle rétrocédée d’enrobé ou de béton.</p></li><li><p>le ruissellement depuis les sols superficiels vers les eaux superficielles du fait du recouvrement de la totalité de la parcelle.</p></li><li><p>la diffusion à travers les canalisations en contact avec les sols contaminés, compte tenu de l’hypothèse de la mise en place de canalisation AEP en fonte ou du remblaiement des tranchées à l’aide de terre saine.</p></li><li><p>le transfert depuis les sols superficiels contaminés vers d’éventuels végétaux comestibles cultivés sur le sol du fait de l’hypothèse de l’interdiction des potagers et arbres fruitiers sur site.</p></li></ul>",
    transferRoutes:
      "<p>Les voies de transfert sur site sont donc constituées par :</p><ul><li><p>la volatilisation des polluants et leur transfert sous forme gazeuse,</p></li><li><p>l’infiltration de polluants vers les eaux souterraines,</p></li><li><p>le transfert des eaux souterraines vers les eaux superficielles.</p></li></ul>",
    offsiteTransfer:
      "<p>Les voies de transfert hors site sont susceptibles d’être constituées par :</p><ul><li><p>la volatilisation des contaminants et leur transfert sous forme gazeuse depuis les eaux souterraines vers l’air ambiant,</p></li><li><p>le transfert des eaux souterraines vers les eaux superficielles par la relation nappe rivière,</p></li><li><p>le transfert depuis les eaux souterraines vers les végétaux du fait de leur utilisation pour l’arrosage,</p></li><li><p>le transfert depuis les eaux superficielles vers les poissons,</p></li><li><p>les échanges entre l’air du site et les alentours (négligeables).</p></li></ul><p>La synthèse des éléments précités est représentée sur le schéma conceptuel  ci-après.</p>",
    piezometricLevelTableTitle: "Relevé des niveaux piezometriques",
    filteredWaterCompositionsTitle:
      "Mesures des paramètres physico-chimiques des eaux prélevées",
    filteredGroundWaterCompositionsTitle:
      "Mesures des paramètres physico-chimiques des eaux prélevées",
  },
  en: {
    conceptMapUpdateIntroduction:
      "<p>The conceptual site diagram, detailed below, assesses the potential impacts of the site with respect to current users/future users by considering its current/future development.</p>",
    assumptionsConsidered:
      "<ul><li><p>A superficial covering of the site soils (concrete slab, bituminous coating, healthy topsoil) blocking any direct transfer between the soils in place and the future users of the site;</p></ li><li><p>An addition of XX cm of healthy topsoil to the bare soil areas of the site;</p></li><li><p>A ban on fruit and vegetable cultivation on site&nbsp;;</p></li><li><p>A ban on wells and groundwater catchments at the right of the site&nbsp;;</p></li><li><p>A implementation of AEP pipes in cast iron or placed in trenches backfilled with healthy earth;</p></li><li><p> …</p></li></ul>",
    sourcesOfContamination:
      "<ul><li><p>Soils contaminated by hydrocarbons…&nbsp;;</p></li><li><p>Groundwater, contaminated by heavy metals (Pb, Zn…)</ p></li><li><p>…</p></li></ul>",
    humanTargetIdentification:
      "<p>The current/future use considered is a residential/tertiary/craft/commercial/industrial/agricultural type of use.</p><p>In this context, the site users, targets likely to be exposed are adult and child residents/workers.</p>",
    analysisInterpretationIntroduction:
      "<p>The interpretation of the results is performed in comparison to the reference values presented previously.</p><p>For each environment check the consistency of the results/field observations and expected results.</p>",
    analysisInterpretation:
      "Interpretation to be presented in a coherent way, by typology of parameters sought or by area of investigation, according to the context",
    exposureMediaAndTheirUses:
      "<p>The assumption of covering all soils being taken, the soil environment is not considered as an environment of direct exposure for humans. Indeed, the planned developments eliminate any possibility of skin contact with polluted surface soils, direct ingestion of polluted surface soils and inhalation of dust from polluted soils.</p><p>The possibility of a ban vegetable gardens or fruit trees being taken into consideration, exposure related to the ingestion of plants grown on polluted soils is also not taken into account.</p><p>The air environment is likely to be impacted by volatile polluting substances present in the soil.</p><p>As the ban on the use of groundwater at the site is considered, the exposures linked to the use of this water at the site are not taken into account. On the other hand, the use of groundwater by individuals for watering, irrigation and swimming pool filling, downstream of the site, being potentially carried out in this environment is therefore considered as an off-site exposure environment.</p> ><p>Surface waters are used for fishing activities downstream of the site, this environment is also considered off-site in this diagram.</p><p>In this context, the exposure environments on site are limited to:</p><ul><li><p>the indoor ambient air of future buildings and the outdoor ambient air of the site,</p></li><li><p> groundwater,</p></li><li><p>surface water.</p></li></ul>",
    soilContaminantTransfer:
      "<p>Contaminants in soils are likely to transfer to:</p><ul><li><p>indoor or outdoor ambient air, by volatilization from the source of pollution and transfer in gaseous form,</p></li><li><p>underground water by infiltration. </p></li></ul>",
    undergroundWatersContaminantTransfer:
      "<p>Contaminants potentially present in groundwater are likely to transfer to:</p><ul><li><p>indoor or outdoor ambient air, by volatilization from water and transfer in gaseous form,</p></li><li><p>surface water due to existing relationships between river and groundwater.</p></li></ul>",
    contaminantNotTransfer:
      "<p>On the other hand, the following transfer paths are not taken into account:</p><ul><li><p>the flight of dust from the superficial soils due to the installation of a soil cover at the right of the site, and the presence at the right of the retroceded plot of asphalt or concrete.</p></li><li><p>runoff from surface soils to surface water due to the covering of the entire plot.</p></li><li> <p>diffusion through the pipes in contact with the contaminated soil, taking into account the hypothesis of the installation of AEP cast iron pipes or the backfilling of the trenches using healthy earth.</p>< /li><li><p>the transfer from contaminated surface soils to any edible plants grown on the ground due to the hypothesis of the prohibition of vegetable gardens and fruit trees on site.</p></ li></ul>",
    transferRoutes:
      "<p>On-site transfer paths therefore consist of:</p><ul><li><p>volatilization of pollutants and their transfer in gaseous form,</p></li><li><p>infiltration of pollutants into groundwater,</p></ li><li><p>the transfer of groundwater to surface water.</p></li></ul>",
    offsiteTransfer:
      "<p>Off-site transfer pathways are likely to consist of:</p><ul><li><p>volatilization of contaminants and their transfer in gaseous form from groundwater to ambient air,</p></li><li><p>transfer from groundwater to surface water by the river aquifer relationship,</p></li><li><p>transfer from groundwater to plants due to their use for watering,</p></li>< li><p>transfer from surface water to fish,</p></li><li><p>exchanges between the site's air and the surrounding area (negligible).</p></ li></ul><p>The synthesis of the aforementioned elements is represented on the conceptual diagram in Figure below.</p>",
    piezometricLevelTableTitle: "Survey of piezometric levels",
    filteredWaterCompositionsTitle:
      "Measurements of the physico-chemical parameters of the water sampled",
    filteredGroundWaterCompositionsTitle:
      "Measurements of the physico-chemical parameters of the water sampled",
  },
};
