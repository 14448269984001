export const SAMPLE_STATUS_PER_COLOR = {
  SENT_TO_LABORATORY: "#A9C409",
  VALIDATED_AND_TO_SEND: "#42C8BE",
  NOT_ENTERED: "#00ACE8",
  SENT_FOR_VALIDATION: "#D80F0C",
};

export const SAMPLES_STATUS = {
  SENT_TO_LABORATORY: "SENT_TO_LABORATORY",
  VALIDATED_AND_TO_SEND: "VALIDATED_AND_TO_SEND",
  NOT_ENTERED: "NOT_ENTERED",
  SENT_FOR_VALIDATION: "SENT_FOR_VALIDATION",
};

export const SAMPLES_TYPES = {
  COMPOSITE: "COMPOSITE",
  STANDARD: "STANDARD",
  SUPERFICIAL: "SUPERFICIAL",
};

export const SAMPLES_MATRIX = {
  SOIL: "SOIL",
  UNDERGROUND_WATERS: "UNDERGROUND_WATERS",
  AMBIENT_AIR: "AMBIENT_AIR",
  SOIL_GAS: "SOIL_GAS",
  EXCAVATED_SOIL: "EXCAVATED_SOIL",
};

export const SAMPLING_NATURE = {
  ACTIVE: "ACTIVE",
  PASSIVE: "PASSIVE",
  CANISTER: "CANISTER",
};

export const SAMPLE_SOIL_SAMPLING_PROTOCOL = {
  UNITARY: "UNITARY",
  LAYERED: "LAYERED",
  MACAOH: "MACAOH",
};

export const SAMPLE_EXCAVATED_SOIL_SAMPLING_PROTOCOL = {
  UNITARY: "UNITARY",
  COMPOSITE: "COMPOSITE",
};

export const CONTROL_SAMPLE_PLACEMENT = {
  INTERIOR: "INTERIOR",
  EXTERIOR: "EXTERIOR",
};

export const MATERIAL_SAMPLING_LINE = {
  PTFE: "PTFE",
  PEHD: "PEHD",
  PP: "PP",
};

export const PRIORITY_LEVEL_OPTIONS = {
  STANDARD: "STD",
  ONE_DAY: "1DAY",
  TWO_DAYS: "2DAYS",
  THREE_DAYS: "3DAYS",
  ON: "ON",
};

export const SUPPORT_TYPE_ACTIVE = [
  "ACTIVATED_CARBON_400_200",
  "ACTIVATED_CARBON_100_50",
  "CARULITE_500",
  "XAD2",
  "CARULITE_200",
];

export const SUPPORT_TYPE_PASSIVE = ["RADIELLO_130", "RADIELLO_145"];

export const SAMPLES_ACTIONS = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  DELETE: "DELETE",
};
