export const soilDefaultValues = {
  fr: {
    soilMeasurements: "<p>Les observations suivantes ont été effectuées : </p>",
    strategySamples:
      "<p>Les sondages ont été réalisés à l’aide de&nbsp;:</p><ul><li><p> Tarière manuelle (de type EDELMAN)&nbsp;;</p></li></ul><ul><li><p>Marteau percuteur portatif de type NORDMEYER avec carottier à fenêtre (l 1&nbsp;m et Ø 36/40 mm)&nbsp;;</p></li><li><p> Sondeuse mécanique sur chenille de type SEDIDRILL SD 80 ou autre si sous-traité, équipée de tarières hélicoïdales emboîtables (longueur 1,50&nbsp;m et Ø 63 mm)&nbsp;;</p></li><li><p>Sondeuse mécanique sur chenille SEDIDRILL 200 RPVL ou SOCOMAFOR ou APAGEO ou autre si sous-traité, avec tarières hélicoïdales emboîtables (longueur 1,50&nbsp;m, Ø 80 mm) (rotation) ou carottier échantillonneur à fenêtre (longueur 1&nbsp;m, Ø 54/47 mm) (percussion) ou carottier échantillonneur à gaine (longueur 1&nbsp;m, Ø 57/43 mm) (percussion) pour prélèvement sous gaine&nbsp;;</p></li><li><p>Pelle mécanique.</p></li></ul>",
    postSurveyTreatment:
      "<p>Lorsque les prélèvements ont été effectués, les sondages ont été rebouchés avec les cuttings non prélevés et les revêtements de surface (béton, enrobés) ont été reformés par un bouchon en ciment / par de l’enrobé à froid. Les cuttings excédentaires ont été transportés vers notre laboratoire pour traitement ultérieur en centre adapté ou laissés sur site pour prise en charge par le Maître d’ouvrage.</p><p>Les investigations réalisées par SOCOTEC Environnement ont permis la constitution de XX échantillons de sols, prélevés par tranche de 1&nbsp;m ou par horizon organoleptiquement différent, prélèvement systématique stratifié par passe d’environ un mètre sur toute la hauteur des sondages ou par horizon homogène. Mesures et observations de terrain</p><p>Chaque point de sondage a fait l’objet d’une fiche de sondage et de prélèvement indiquant notamment, la coupe lithologique avec la nature des formations géologiques rencontrées, les indices organoleptiques, la profondeur et la référence des échantillons. Ces fiches sont jointes en Annexe 2.</p><p>Des mesures de COV ont été réalisées sur les sols prélevés au moyen d’un détecteur à photo-ionisation portatif (PID) préalablement étalonné par nos soins.</p>",
    soilLaboratoryAnalysisTreatment:
      "Parmi les XX échantillons prélevés, X ont été sélectionnés et envoyés au laboratoire EUROFINS accrédité par le COFRAC pour analyses. Les X autres ont également été envoyés au laboratoire et mis en réserve.",
    soilInvestigationPlansTitle: "Plans d'investigations sur les sols",
    soilAnalysisProgramTitle: "Programmes d'analyses",
    soilConditioning:
      "<p>Chaque échantillon a été immédiatement conditionné dans un flacon étanche en verre blanc de 375 mL fourni par le laboratoire. Chaque flacon est étiqueté puis conservé à basse température et à l’obscurité dans une glacière, jusqu’à l’expédition au laboratoire pour réalisation des analyses.</p><p>La date de transport des échantillons correspond à l’intervalle entre la date de prélèvement et la date de réception des échantillons au laboratoire d’analyses. Ces dates sont mentionnées dans les rapports d’analyses du laboratoire présents en pièce-jointe de ce rapport.</p><p>Les prélèvements de sols ont été effectués et conditionnés conformément aux normes de la série NF ISO 18400.</p>",
  },
  en: {
    soilMeasurements: "<p>The following observations were made: </p>",
    strategySamples:
      "<p>The borings were carried out using:</p><ul><li><p>Manual auger (EDELMAN type);</p></li><li><p> Portable hammer drill of the NORDMEYER type with window core barrel (l 1 m and Ø 36/40 mm);</p></li><li><p>SEDIDRILL SD 80 type mechanical crawler drill or other if subcontracted, equipped with interlocking helical augers (length 1.50 m and Ø 63 mm);</p></li><li><p>SEDIDRILL 200 RPVL or SOCOMAFOR or APAGEO or other if subcontracted, with interlocking helical augers (length 1.50 m, Ø 80 mm) (rotation) or window sampler (length 1 m, Ø 54/47 mm) (percussion) or sheath sampler (length 1 m, Ø 57/43 mm) (percussion) for sampling under sheath;</p></li><li><p>Mechanical shovel.</p></li></ul>",
    postSurveyTreatment:
      "<p>When the samples were taken, the boreholes were refilled with the untaken cuttings and the surface coatings (concrete, asphalt) were reformed by a cement plug / by cold mix. The excess cuttings were transported to our laboratory for further treatment in a suitable center or left on site to be taken care of by the Owner.</p><p>The investigations carried out by SOCOTEC Environment allowed the constitution of XX soil samples, taken by 1&nbsp;m increments or by organoleptically different horizons, systematic sampling stratified by pass of about one meter over the entire height of the boreholes or by homogeneous horizon. Field measurements and observations</p><p>Each sounding point was the subject of a sounding and sampling sheet indicating in particular, the lithological section with the nature of the geological formations encountered, the organoleptic indices, the depth and the reference of the samples. These sheets are attached in Appendix 2.</p><p>VOC measurements were performed on the sampled soils using a portable photoionization detector (PID) previously calibrated by us.</p>",
    soilLaboratoryAnalysisTreatment:
      "Among the XX samples taken, X were selected and sent to the EUROFINS laboratory accredited by the COFRAC for analysis. The X others were also sent to the laboratory and put in reserve.",
    soilInvestigationPlansTitle: "Soil investigation plans",
    soilAnalysisProgramTitle: "Analysis program",
  },
};
