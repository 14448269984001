export const lithologyMessages = {
  en: {
    ALLUVIUM: "Alluvium",
    CLAY: "Clay",
    CLAY_GRAVEL: "Clay gravel",
    CLAY_SILT: "Clay silt",
    CLAY_SILT_GRAVEL: "Clay silt gravel",
    CLAY_SILT_SAND: "Clay silt sand",
    CLAY_SAND: "Clay sand",
    CLAY_SAND_GRAVEL: "Clay sand gravel",
    CLAY_SAND_SILT: "Clay sand silt",
    LIMESTONE: "Limestone",
    PEBBLES: "Pebbles",
    GRANITE: "Granite",
    GRAVEL: "Gravel",
    GRAVE: "Grave",
    SANDSTONE: "Sandstone",
    SILT: "Silt",
    SILT_CLAY: "Silt clay",
    SILT_CLAY_GRAVEL: "Silt clay gravel",
    SILT_CLAY_SAND: "Silt clay sand",
    SILT_GRAVEL: "Silt gravel",
    SILT_SAND: "Silt sand",
    SILT_SAND_CLAY: "Silt sand clay",
    SILT_SAND_GRAVEL: "Silt sand gravel",
    CONCRETE_SLAB: "Concrete slab",
    COATED: "Coated",
    LOESS: "Loess",
    LOESS_CLAY: "Loess clay",
    LOESS_CLAY_GRAVEL: "Loess clay gravel",
    LOESS_CLAY_SAND: "Loess clay sand",
    LOESS_GRAVEL: "Loess gravel",
    LOESS_SAND: "Loess sand",
    LOESS_SAND_CLAY: "Loess sand clay",
    LOESS_SAND_GRAVEL: "Loess sand gravel",
    MARL: "Marl",
    EARTHWORKS: "Earthworks",
    EARTHWORKS_CLAY_GRAVEL: "Earthworks clay gravel",
    EARTHWORKS_CLAY_SAND: "Earthworks clay sand",
    EARTHWORKS_SAND_CLAY: "Earthworks sand clay",
    EARTHWORKS_SAND_GRAVEL: "Earthworks sand gravel",
    NATURAL_ROCK: "Natural rock",
    SAND: "Sand",
    SAND_CLAY: "Sand clay",
    SAND_CLAY_GRAVEL: "Sand clay gravel",
    SAND_CLAY_SILT: "Sand clay silt",
    SAND_GRAVEL: "Sand gravel",
    SAND_SILT: "Sand silt",
    SAND_SILT_CLAY: "Sand silt clay",
    SAND_SILT_GRAVEL: "Sand silt gravel",
    TOPSOIL: "Topsoil",
    SHALE: "Shale",
    BEIGE: "Beige",
    WHITE: "White",
    BLUE: "Blue",
    GRAY: "Gray",
    YELLOW: "Yellow",
    BROWN: "Brown",
    BLACK: "Black",
    OCHER: "Ocher",
    ORANGE: "Orange",
    RED: "Red",
    GREEN: "Green",
    PINK: "Pink",
  },
  fr: {
    ALLUVIUM: "Alluvions",
    CLAY: "Argiles",
    CLAY_GRAVEL: "Argiles graveleuses",
    CLAY_SILT: "Argiles limoneuses",
    CLAY_SILT_GRAVEL: "Argiles limono-graveleuses",
    CLAY_SILT_SAND: "Argiles limono-sableuses",
    CLAY_SAND: "Argiles sableuses",
    CLAY_SAND_GRAVEL: "Argiles sablo-graveleuses",
    CLAY_SAND_SILT: "Argiles sablo-limoneuses",
    CONCRETE_SLAB: "Dalle de béton",
    COATED: "Enrobé",
    LIMESTONE: "Calcaire",
    PEBBLES: "Galets",
    GRANITE: "Granite",
    GRAVEL: "Graviers",
    GRAVE: "Graves",
    SANDSTONE: "Grès",
    SILT: "Limons",
    SILT_CLAY: "Limons argileux",
    SILT_CLAY_GRAVEL: "Limons argilo-graveleux",
    SILT_CLAY_SAND: "Limons argilo-sableux",
    SILT_GRAVEL: "Limons graveleux",
    SILT_SAND: "Limons sableux",
    SILT_SAND_CLAY: "Limons sablo-argileux",
    SILT_SAND_GRAVEL: "Limons sablo-graveleux",
    LOESS: "Loeuss",
    LOESS_CLAY: "Loeuss argileux",
    LOESS_CLAY_GRAVEL: "Loeuss argilo-graveleux",
    LOESS_CLAY_SAND: "Loeuss argilo-sableux",
    LOESS_GRAVEL: "Loeuss graveleux",
    LOESS_SAND: "Loeuss sableux",
    LOESS_SAND_CLAY: "Loeuss sablo-argileux",
    LOESS_SAND_GRAVEL: "Loeuss sablo-graveleux",
    MARL: "Marnes",
    EARTHWORKS: "Remblais",
    EARTHWORKS_CLAY_GRAVEL: "Remblais argilo-graveleux",
    EARTHWORKS_CLAY_SAND: "Remblais argilo-sableux",
    EARTHWORKS_SAND_CLAY: "Remblais sablo-argileux",
    EARTHWORKS_SAND_GRAVEL: "Remblais sablo-graveleux",
    NATURAL_ROCK: "Roche naturelle",
    SAND: "Sables",
    SAND_CLAY: "Sables argileux",
    SAND_CLAY_GRAVEL: "Sables argilo-graveleux",
    SAND_CLAY_SILT: "Sables argilo-limoneux",
    SAND_GRAVEL: "Sables graveleux",
    SAND_SILT: "Sables limoneux",
    SAND_SILT_CLAY: "Sables limono-argileux",
    SAND_SILT_GRAVEL: "Sables limono-graveleux",
    TOPSOIL: "Terres végétale",
    SHALE: "Schistes",
    BEIGE: "Beige",
    WHITE: "Blanc",
    BLUE: "Bleu",
    GRAY: "Gris",
    YELLOW: "Jaune",
    BROWN: "Marron",
    BLACK: "Noir",
    OCHER: "Ocre",
    ORANGE: "Orange",
    RED: "Rouge",
    GREEN: "Vert",
    PINK: "Rose",
  },
};
