export const technicalSummaryDefaultValues = {
  fr: {
    siteVisitSuperficyOccupiedBy:
      "description succincte des activités / bâtiments et extérieurs",
    presenceOfAnthropogenic:
      "XXX ainsi que la présence suspectée de remblais anthropiques",
    natureOfTheAquifer: "la nature de l’aquifère et/ou de sa proximité",
    presenceOfInstitution:
      "XX (centre urbain avec la présence d’établissements sensibles et d’habitats, présence de ZNIEFF, zone industrielle).",
    nonTechnicalSummaryConclusion:
      "Si mesures de sécurité à mettre en œuvre : préciser les mesures",
    technicalSummaryInvestigationsPlanned:
      "<ul><li><p>Réalisation de X sondages de sols jusque X&nbsp;m de profondeur au droit des installations / activités à risques recensées</p></li><li><p>Implantation de X piézomètres / piézairs jusque X&nbsp;m de profondeur</p></li><li><p>Réalisation de X prélèvements d’eaux souterraines/ superficielles, de sédiments de gaz des sols, d’air ambiant, de denrées alimentaires, d’eau du robinet….</p></li><li><p>Prélèvement d’eaux souterraines le XX/XX/XXXX et recherche des composés XXX, XXX, XXX.</p></li><li><p>Investigations sur les gaz des sols (A230)&nbsp;:</p></li><li><p>Réalisation de XX sondages de sol le XX/XX/XXXX jusqu’à une profondeur maximale de XX&nbsp;m&nbsp;;</p></li><li><p>Recherche des composés XXX, XXX, XXX.</p></li></ul>",
    technicalSummaryresultsInterpretation:
      "<ul><li><p>Sur les sols&nbsp;:</p></li><li><p>Sur les eaux souterraines&nbsp;:</p></li><li><p>Sur les gaz des sols&nbsp;:</p></li><li><p>Sur les terres excavées / à excaver&nbsp;:</p></li></ul>",
    technicalSummaryRecommandations:
      "<ul><li><p>la réalisation d’investigations complémentaires de dimensionnement des zones de contamination&nbsp;;</p></li><li><p> l’élaboration d’un plan de gestion pour déterminer les mesures à mettre en œuvre au regard des intérêts et enjeux à préserver, de l’usage futur considéré et du projet présenté&nbsp;;</p></li><li><p>la surveillance des eaux souterraines à poursuivre&nbsp;;</p></li><li><p>d’engager une procédure de cessation d’activité&nbsp;;</p></li><li><p>la mise en sécurité des ouvrages&nbsp;: mise en place d’une rétention au droit des fûts d’huile, nettoyage, dégazage, inertage de la cuve enterrée…</p></li><li><p> la conservation de la mémoire des contaminations mises en évidence&nbsp;;</p></li><li><p>Bien rappeler les mesures d’urgence à mettre en œuvre</p></li></ul>",
  },
  en: {
    siteVisitSuperficyOccupiedBy:
      "brief description of the activities / buildings and exteriors",
    presenceOfAnthropogenic:
      "XXX as well as the suspected presence of anthropogenic embankments",
    natureOfTheAquifer: "the nature of the aquifer and/or its proximity",
    presenceOfInstitution:
      "XX (urban center with the presence of sensitive establishments and habitats, presence of ZNIEFF, industrial zone).",
    nonTechnicalSummaryConclusion:
      "If security measures to be implemented: specify the measures",
    technicalSummaryInvestigationsPlanned:
      "<ul><li><p>Carrying out of X soil surveys to a depth of X&nbsp;m in line with the facilities / identified risk activities</p></li><li><p>Installation of X piezometers / piezairs up to X&nbsp;m depth</p></li><li><p>Realization of X samples of groundwater/ surface water, soil gas sediments, ambient air, foodstuffs, water from the tap….</p></li><li><p>Sample groundwater on XX/XX/XXXX and search for compounds XXX, XXX, XXX.</p></li><li> <p>Soil gas investigations (A230)&nbsp;:</p></li><li><p>Conducting XX soil soundings on XX/XX/XXXX to a maximum depth of XX&nbsp; m&nbsp;;</p></li><li><p>Search for compounds XXX, XXX, XXX.</p></li></ul>",
    technicalSummaryresultsInterpretation:
      "<ul><li><p>On soils:</p></li><li><p>On groundwater:</p></li><li><p>On soil gases:</p></li><li><p>On excavated land / to be excavated:</p></li></ul>",
    technicalSummaryRecommandations:
      "<ul><li><p>carrying out additional investigations to size the contamination zones;</p></li><li><p> developing a management plan to determine the measures to be implemented with regard to the interests and issues to be preserved, the future use considered and the project presented;</p></li><li><p>groundwater monitoring to be continued;</ p></li><li><p>to initiate a cessation of activity procedure;</p></li><li><p>the securing of structures: implementation of 'retention in line with the oil drums, cleaning, degassing, inerting of the buried tank...</p></li><li><p> preservation of the memory of the contaminations brought to light&nbsp;;</p ></li><li><p>Remember the emergency measures to be implemented</p></li></ul>",
  },
};
