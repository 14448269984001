export const COMPANY_TYPE_CHOICES = {
  SUBCONTRACTOR: {
    label: "SUBCONTRACTOR",
    value: "SUBCONTRACTOR",
  },
  RENTAL: {
    label: "RENTAL",
    value: "RENTAL",
  },
};
