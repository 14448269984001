export const PREFIX_OPTIONS = [
  { name: "Piézo", value: "PZ" },
  { name: "Piézair", value: "PZR" },
  { name: "Sol", value: "S" },
];

export const SURVEY_TYPES = {
  SOIL: "SOIL",
  UNDERGROUND_WATERS: "UNDERGROUND_WATERS",
  AMBIENT_AIR: "AMBIENT_AIR",
  SOIL_GAS: "SOIL_GAS",
  EXCAVATED_SOIL: "EXCAVATED_SOIL",
};

export const SURVEY_UNDERGROUND_WATERS_TYPE_TOOL = {
  PIEZOMETER: "PIEZOMETER",
  EXISTING_WORK: "EXISTING_WORK",
};

export const SURVEY_SOIL_GAS_TOOL = {
  PIEZAIR: "PIEZAIR",
  VAPOR_PIN: "VAPOR_PIN",
  GAS_CANE: "GAS_CANE",
};

export const WEATHER_CONDITIONS = {
  DRY_LIGHTLY_CLOUDED: "DRY_LIGHTLY_CLOUDED",
  DRY_CLOUDED: "DRY_CLOUDED",
  LIGHT_RAIN: "LIGHT_RAIN",
  HEAVY_RAIN: "HEAVY_RAIN",
  SNOWY: "SNOWY",
  FREEZING: "FREEZING",
  DUSK_CONDITIONS: "DUSK_CONDITIONS",
};

export const CASING_NATURE = {
  PVC: "PVC",
  PEHD: "PEHD",
  INOX: "INOX",
};

export const CLAY_PLUG_TYPE = {
  BENTONITE: "BENTONITE",
  OREGONITE: "OREGONITE",
};

export const HYDROGEOLOGICAL_POSITION = {
  UPSTREAM: "UPSTREAM",
  DOWNSTREAM: "DOWNSTREAM",
  UPSTREAM_LATERAL: "UPSTREAM_LATERAL",
  DOWNSTREAM_LATERAL: "DOWNSTREAM_LATERAL",
  LATERAL: "LATERAL",
  UNKNOWN: "UNKNOWN",
};

export const SURVEY_TYPE_CHOICES = [
  SURVEY_TYPES.SOIL,
  SURVEY_TYPES.UNDERGROUND_WATERS,
  SURVEY_TYPES.AMBIENT_AIR,
  SURVEY_TYPES.SOIL_GAS,
  SURVEY_TYPES.EXCAVATED_SOIL,
];

export const SURVEY_CUTTING_MANAGEMENT_CHOICES = {
  FILLING: "FILLING",
  ON_SITE_WASTE_MANAGEMENT: "ON_SITE_WASTE_MANAGEMENT",
  OFF_SITE_WASTE_MANAGEMENT: "OFF_SITE_WASTE_MANAGEMENT",
};

export const SURVEY_FILLING_CHOICES = [
  "CUTTINGS",
  "COLD_MIX",
  "CONCRETE",
  "SAND",
];

export const SAMPLING_PROTOCOLS_NAME = {
  UNIT: "UNIT",
  BY_JUDGMENT: "BY_JUDGMENT",
  COMPOSITE: "COMPOSITE",
  LAYERED: "LAYERED",
};

export const SAMPLING_PROTOCOLS = [
  SAMPLING_PROTOCOLS_NAME.UNIT,
  SAMPLING_PROTOCOLS_NAME.BY_JUDGMENT,
  SAMPLING_PROTOCOLS_NAME.LAYERED,
];
export const EXCAVATED_SOIL_SAMPLING_PROTOCOLS = [
  SAMPLING_PROTOCOLS_NAME.UNIT,
  SAMPLING_PROTOCOLS_NAME.COMPOSITE,
  SAMPLING_PROTOCOLS_NAME.LAYERED,
];

export const SAMPLING_PROTOCOLS_BY_SURVEY_TYPE = {
  SOIL: SAMPLING_PROTOCOLS,
  UNDERGROUND_WATERS_SAMPLING: SAMPLING_PROTOCOLS,
  AMBIENT_AIR_SAMPLING: SAMPLING_PROTOCOLS,
  SOIL_GAS_SOIL_SAMPLING: SAMPLING_PROTOCOLS,
  EXCAVATED_SOIL: EXCAVATED_SOIL_SAMPLING_PROTOCOLS,
};

export const EXCAVATED_SOIL_TYPE_OF_SAMPLING = {
  ON_PILE: "ON_PILE",
  ON_WALLS_BOTTOM_OF_EXCAVATIONS: "ON_WALLS_BOTTOM_OF_EXCAVATIONS",
};

export const SURVEYS_ACTIONS = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  DELETE: "DELETE",
};
