import { ambientAirDefaultValues } from "./ambientAirDefaultValues";
import { excavatedGroundDefaultValues } from "./excavatedGroundDefaultValues";
import { soilGasesDefaultValues } from "./soilGasesDefaultValues";
import { foodTapWaterDefaultValues } from "./foodTapWaterDefaultValues";
import { provisionalProgramDefaultValues } from "./provisionalProgramDefaultValues";
import { soilDefaultValues } from "./soilDefaultValues";
import { undergroundWaterDefaultValues } from "./undergroundWaterDefaultValues";
import { conceptMapUpdateDefaultValues } from "./interpretation/conceptMapUpdateDefaultValues";
import { fieldMeasurementsAndObservationsDefaultValues } from "./interpretation/fieldMeasurementsAndObservationsDefaultValues";
import { referenceValueDefaultValues } from "./interpretation/referenceValueDefaultValues";
import { siteVisitDefaultValues } from "./siteVisitDefaultValues";
import { technicalSummaryDefaultValues } from "./technicalSummaryDefaultValues";
import { historicalStudyDefaultValues } from "./historicalStudyDefaultValues";
import { vulnerabilityStudyDefaultValues } from "./vulnerabilityStudyDefaultValues";
import { superficialWaterDefaultValues } from "./superficialWaterDefaultValues";
import { missionPresentationDefaultValues } from "./missionPresentationDefaultValues";
import { conclusionRecommendations } from "@/locales/report/defaultReportValues/conclusionRecommendations";
import { assessmentOfUncertainties } from "@/locales/report/defaultReportValues/assessmentOfUncertainties";
export const defaultReportValues = {
  fr: {
    ...ambientAirDefaultValues.fr,
    ...excavatedGroundDefaultValues.fr,
    ...soilGasesDefaultValues.fr,
    ...foodTapWaterDefaultValues.fr,
    ...provisionalProgramDefaultValues.fr,
    ...soilDefaultValues.fr,
    ...undergroundWaterDefaultValues.fr,
    ...fieldMeasurementsAndObservationsDefaultValues.fr,
    ...referenceValueDefaultValues.fr,
    ...conceptMapUpdateDefaultValues.fr,
    ...siteVisitDefaultValues.fr,
    ...technicalSummaryDefaultValues.fr,
    ...historicalStudyDefaultValues.fr,
    ...vulnerabilityStudyDefaultValues.fr,
    ...superficialWaterDefaultValues.fr,
    ...missionPresentationDefaultValues.fr,
    ...conclusionRecommendations.fr,
    ...assessmentOfUncertainties.fr,
  },
  en: {
    ...ambientAirDefaultValues.en,
    ...excavatedGroundDefaultValues.en,
    ...soilGasesDefaultValues.en,
    ...foodTapWaterDefaultValues.en,
    ...provisionalProgramDefaultValues.en,
    ...soilDefaultValues.en,
    ...undergroundWaterDefaultValues.en,
    ...fieldMeasurementsAndObservationsDefaultValues.en,
    ...referenceValueDefaultValues.en,
    ...conceptMapUpdateDefaultValues.en,
    ...siteVisitDefaultValues.en,
    ...technicalSummaryDefaultValues.en,
    ...historicalStudyDefaultValues.en,
    ...technicalSummaryDefaultValues.en,
    ...vulnerabilityStudyDefaultValues.en,
    ...superficialWaterDefaultValues.en,
    ...missionPresentationDefaultValues.en,
    ...conclusionRecommendations.en,
    ...assessmentOfUncertainties.en,
  },
};
