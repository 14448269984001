export const IMAGE_TAGS = {
  HISTORIQUE: "HISTORIQUE",
  VISITE_DE_SITE: "VISITE DE SITE",
  INTERVENTION: "INTERVENTION",
};
export const FILE_TAGS = {
  SOUS_TRAITANT: "SOUS TRAITANT",
  MACRO_ANALYSE: "MACRO ANALYSE",
};

export const imageExtensions = [".png", ".jpg", ".jpeg"];
