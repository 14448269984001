import socioGrpcClient from "@/setup/socioGrpcClient";
import { Model } from "@vuex-orm/core";
import { sspMetadata } from "@/utils/metadata";

export default function relatedToQuestionnaireModuleFactory(vuexClassModel) {
  /**
   * Provide VUEX module for models related to Questionnaire
   *
   * @param {vuex-orm.core.Model} vuexClassModel - Your model
   * @return {Object} returnedValue
   */

  // Define mutation/action variables
  if (Object.getPrototypeOf(vuexClassModel) !== Model) {
    throw new Error(
      `${vuexClassModel.toString()} seems to not be a Vuex model…`
    );
  }
  const messagePrefix = vuexClassModel.protoMessagePrefix;
  // Define getters method names
  const getCurrentsObject = `getCurrents${messagePrefix}`;
  // Define getter methods names
  const fetchAllObject = `fetchAll${messagePrefix}`;
  const createOrUpdateObject = `createOrUpdate${messagePrefix}`;
  const destroyObject = `destroy${messagePrefix}`;
  // Retrieve gRPC objects by model name
  const getControllerPromiseClientString = () =>
    socioGrpcClient.ssp_back.report[`${messagePrefix}ControllerPromiseClient`];

  const getGrpcRequest = (requestTypeAsString = "") => {
    try {
      return socioGrpcClient.ssp_back.report[
        `${messagePrefix}${requestTypeAsString}Request`
      ];
    } catch (error) {
      throw `Cannot instanciate new module 'socioGrpcClient.ssp_back.report.'${messagePrefix}${requestTypeAsString}', is grpc-api client updated ?:\n${error}`;
    }
  };
  const getters = {
    [getCurrentsObject]: (state, getters, rootState, rootGetters) => {
      const currentReportQuestionnaireUuid =
        rootGetters["report/getCurrentReportQuestionnaire"].uuid;

      const currentsObjects = vuexClassModel
        .query()
        .where("reportQuestionnaire", currentReportQuestionnaireUuid);
      if (currentsObjects.exists()) {
        return currentsObjects.get();
      }
      return [];
    },
  };
  const actions = {
    [fetchAllObject]: async (context, metadata) => {
      const listRequestModule = getGrpcRequest("List");
      const request = new listRequestModule();
      const responseResult =
        await getControllerPromiseClientString().utils.listAllObjects(
          request,
          metadata
        );
      return await vuexClassModel.create({
        data: responseResult,
      });
    },
    [createOrUpdateObject]: async ({ rootGetters }, instance) => {
      const request = socioGrpcClient.javascriptToRequest(
        getGrpcRequest(),
        instance
      );
      // Vuex auto fill uuid with "$" when not user provided.
      // We consider uuid field include "$" as it is empty
      if (!instance?.uuid || instance.uuid.includes("$")) {
        request.setReportQuestionnaire(
          rootGetters["report/getCurrentReportQuestionnaire"].uuid
        );
        const response = await getControllerPromiseClientString().create(
          request,
          sspMetadata()
        );
        const responseInstance = response.toObject();
        return await vuexClassModel.insert({
          data: responseInstance,
        });
      } else {
        const response = await getControllerPromiseClientString().update(
          request,
          sspMetadata()
        );
        const responseInstance = response.toObject();
        return await vuexClassModel.update({
          data: responseInstance,
        });
      }
    },
    [destroyObject]: async (context, instance) => {
      const request = socioGrpcClient.javascriptToRequest(
        getGrpcRequest("Destroy"),
        { uuid: instance.uuid }
      );
      await getControllerPromiseClientString().destroy(request, sspMetadata());
      return await vuexClassModel.delete(instance.uuid);
    },
  };
  return {
    getters: getters,
    actions: actions,
  };
}
