export const foodTapWaterDefaultValues = {
  fr: {
    presentContaminants:
      "dans les sols, les eaux d’arrosage, les poussières atmosphériques.",
    perimiterDelimitation:
      "<p>par la pollution des sols ou par les émissions en provenance de l’installation industrielle :</p><ul><li><p>Parcelles n° ou X m autour du site</p></li></ul><p>Compte tenu du périmètre important de l’étude, celui-ci a été divisé en secteurs distincts.</p><p>Les autres facteurs susceptibles d’interférer avec les impacts de l’installation sont les suivants&nbsp;: </p>",
    historicalElements:
      "<ul><li><p>Les dates de début et le cas échéant de fin de l’exploitation ;</p></li><li><p>Les quantités et nature des rejets (sources de contamination) ;</p></li><li><p>les caractéristiques de l’installation (superficie, hauteur des cheminées, nature des substances toxiques suspectées ou avérées, quantités de déchets enfouis pour une décharge…) ;</p></li><li><p>les dispositifs mis en place pour la réduction (filtres) et les contrôles à l’émissaire des rejets (après traitement) ;</p></li><li><p>les dispositifs de surveillance de l’environnement... </p></li></ul>",
    gardenInformations:
      "<ul><li><p>leurs localisations et tailles,</p></li><li><p>la nature de leurs productions,</p></li><li><p>leur historique et les pratiques de culture (e.g. types et quantités d’intrants, apport de cendres, âge et taille du potager),</p></li><li><p>la composition du foyer (les consommateurs) et le potentiel d’exploitation (intensive, modérée ou faible),</p></li><li><p>les quantités produites des années précédentes. </p></li></ul>",
    environmentalStudy:
      "<ul><li><p>la nature des sols (géologie, pédologie et topographie) ;</p></li><li><p>l’extension de la contamination en provenance de l’installation (sens d’écoulement des nappes, débit des rivières et étendue de la zone inondable, précipitation, rose des vents) ;</p></li><li><p>les données relatives au fond pédogéochimique local et le cas échéant au fond anthropique lié à des sources secondaires de contamination ;</p></li><li><p>la vulnérabilité de l’environnement ;</p></li><li><p>les paramètres pouvant contribuer aux transferts des polluants vers les végétaux ;</p></li><li><p>le type de plantes cultivées qui permettra de déterminer la profondeur de prélèvement : 0-30 cm pour les plantes potagères, plus profonde pour les arbres&nbsp;;</p></li><li><p>un risque de transfert des polluants depuis la nappe vers dans les arbres fruitiers.</p></li></ul>",
    waterOrigin:
      "L’eau du robinet ou d’arrosage a été échantillonnée en flaconnage adapté après une purge d’une minute du réseau d’eau (10 secondes à débit max, puis 50 secondes à débit normal constant).",
    annexAnalyseModality:
      "Les analyses ont été effectuées selon les méthodes analytiques présentées en Annexe",
    foodLaboratoryAnalysisTitle:
      "Programme analytique réalisé sur les denrées alimentaires / l’eau du robinet",
    foodSampleEnvironmentTitle:
      "Présentation des milieux échantillonnés et des références de végétaux associés",
  },
  en: {
    presentContaminants: "in soils, irrigation water, atmospheric dust.",
    perimiterDelimitation:
      "<p>by soil pollution or by emissions from the industrial installation:</p><ul><li><p>Plots n° or X m around the site</p></li> </ul><p>Given the large scope of the study, it has been divided into distinct sectors.</p><p>Other factors likely to interfere with the impacts of the installation are the following: </p>",
    historicalElements:
      "<ul><li><p>The dates of the start and, where applicable, the end of the operation;</p></li><li><p>The quantities and nature of the discharges (sources of contamination); </p></li><li><p>the characteristics of the installation (area, height of the chimneys, nature of the suspected or proven toxic substances, quantities of waste buried for a landfill, etc.);</p>< /li><li><p>the devices put in place for the reduction (filters) and checks at the outfall of discharges (after treatment);</p></li><li><p>the devices environmental monitoring... </p></li></ul>",
    gardenInformations:
      "<ul><li><p>their locations and sizes,</p></li><li><p>the nature of their productions,</p></li><li><p>their history and cultivation practices (e.g. types and amounts of inputs, ash supply, age and size of vegetable garden),</p></li><li><p>household composition (consumers) and potential exploitation (intensive, moderate or low),</p></li><li><p>the quantities produced in previous years. </p></li></ul>",
    environmentalStudy:
      "<ul><li><p>the nature of the soil (geology, pedology and topography);</p></li><li><p>the extent of the contamination from the installation (direction of runoff, river flow and extent of the flood zone, precipitation, wind rose);</p></li><li><p>data relating to the local pedogeochemical background and, where applicable, the anthropogenic background linked to secondary sources of contamination;</p></li><li><p>the vulnerability of the environment;</p></li><li><p>the parameters that can contribute to the transfer of pollutants to plants;</p></li><li><p>the type of cultivated plants that will determine the sampling depth: 0-30 cm for vegetable plants, deeper for trees;< /p></li><li><p>a risk of transfer of pollutants from the water table to the fruit trees.</p></li></ul>",
    waterOrigin:
      "Tap or irrigation water was sampled in appropriate bottles after a one-minute purge of the water network (10 seconds at maximum flow, then 50 seconds at constant normal flow).",
    annexAnalyseModality:
      "The analyzes were carried out according to the analytical methods presented in the Appendix",
    foodLaboratoryAnalysisTitle:
      "Analytical program carried out on foodstuffs / tap water",
    foodSampleEnvironmentTitle:
      "Presentation of sampled media and associated plant references",
  },
};
