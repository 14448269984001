export const superficialWaterDefaultValues = {
  fr: {
    superficialWaterSamplesCollection:
      "<p>X prélévements d'eaux superficielles/sédiments ont été éffectués sur le cours d'eau XXX situé au NESW et XX m par rapport au site.</p><p>L'implantation des prélèvements a tenu compte du sens d'écoulement de la rivière/du lac, ils ont été implantés comme suit: un en amont et deux en aval.</p>",
    superficialWaterOrSedimentTitle: "eaux superficielles / sédiments",
    superficialWaterInvestigationMaterial:
      "<p>Ils ont été réalisés au moyen d'un bécher muni d’une perche télescopique conformément à la norme ISO 5667-3 (eaux superficielles) /à l’aide d’une drague manuelle ou écope : en plastique ou en métal, benne et carottier conformément à la norme ISO 5667-12 (sédiments). Les échantillons d’eaux superficielles destinés à l’analyse des métaux ont été préalablement filtrés avant conditionnement.</p>",
    superficialWaterConditioning:
      "<p>NF EN ISO 5667-3 (« Qualité de l’eau – Echantillonnage – Partie 3 : Guide général pour la conservation et la manipulation des échantillons ») ou NF EN ISO 5667-15 (sédiments).</p>",
    superficialWaterMeasurements:
      "<p>Les caractéristiques organoleptiques des échantillons prélevées ont été relevées et des mersures ponctuelles sur site pour les eaux ont été réalisées à l'aide d’un boiter multi-paramètres WTW 340i équipé d’une sonde WTW TetraCon 325 et WTW Sen Tix 41 préalabelement étalonnée par nos soins (température, pH et conductivité)</p><p>L'ensemble de ces données est consigné dans les protocoles de prélèvement joints en Annexe.</p>",
    superficialWaterAnnexAnalysisModality:
      "Les analyses ont été effectuées selon les méthodes analytiques indiqués dans les bordereaux d'analyses en Annexe",
    superficialWaterLaboratory:
      "EUROFINS accrédité par le COFRAC ou SYNLAB, accrédité par RvA, reconnu par le COFRAC ou autre.",
    superficialWaterLaboratoryAnalysisTitle:
      "Programme analytique réalisé sur les eaux superficielles / sédiments",
  },
  en: {
    superficialWaterSamplesCollection:
      "<p>X surface water/sediment samplings were taken on stream XXX located NESW and XX m from the site.</p><p>Sample placement took into account the direction of flow of the river/lake, they were located as follows: one upstream and two downstream.</p>",
    superficialWaterSamplesStakeout: "one upstream and one to two downstream",
    superficialWaterOrSedimentTitle: "surface water/sediment",
    superficialWaterInvestigationMaterial:
      "They were realised with a beaker fitted with a telescopic pole in accordance with ISO 5667-3 (surface water) / using a manual dredge or bailer: plastic or metal, bucket and corer in accordance with ISO 5667-12 (sediments). Surface water samples intended for metal analysis have been filtered before conditioning.",
    superficialWaterConditioning:
      "<p>L’ensemble des échantillons a été conditionné en flaconnage adapté fourni par le laboratoire d’analyses (flacons en matériaux adaptés, verre ou PET, avec éventuels réactifs de conservation selon les paramètres à analyser) et conservé au frais en caisson isotherme à faible température (environ 4°C) jusqu’à leur expédition pour analyses au laboratoire par transporteur express, conformément à la norme</p><p>NF EN ISO 5667-3 (“Water quality – Sampling – Part 3: General guidance for the preservation and handling of samples”) or NF EN ISO 5667-15 (sediments).</p> ",
    superficialWaterMeasurements:
      "<p>The organoleptic characteristics of the samples taken were recorded and spot measurements on site for the waters were carried out using a WTW 340i multi-parameter boiter equipped with a WTW TetraCon 325 and WTW Sen Tix 41 probe previously calibrated by us (temperature, pH and conductivity)</p><p>All of these data are recorded in the sampling protocols attached in the Appendix.</p>",
    superficialWaterAnnexAnalysisModality:
      "The analyzes were carried out according to the analytical methods indicated in the test reports in the Appendix",
    superficialWaterLaboratory:
      "EUROFINS accredited by COFRAC or SYNLAB, accredited by RvA, recognized by COFRAC or other.",
    superficialWaterLaboratoryAnalysisTitle:
      "Analytical program carried out on surface waters / sediments",
  },
};
