export const ambientAirDefaultValues = {
  fr: {
    ambientAirSamplingCampaignContext:
      "<ul><li><p>période de faibles précipitations (4,6, 0,2 et 2,6 mm les 3 jours précédents). Ces précipitations autour du bâtiment peuvent induire une augmentation potentielle des flux vers l’air intérieur du bâtiment si la pollution est présente en-dessous du bâtiment,</p></li><li><p>période de températures positives mais basses (températures comprises entre 3 et 9,9°C). Ces conditions de température sont considérées comme neutres pour la volatilisation des gaz du sol (températures &gt; 10°C favorisant la volatilisation)&nbsp;;</p></li><li><p>période de hautes pressions (supérieures à 1013,25 hPa). Ces conditions de fortes pressions atmosphériques sont considérées comme non favorables à la volatilisation des gaz du sol.</p><p>Le XX/XX/20XX, jour des prélèvements de gaz de sols, les conditions suivantes ont été relevées&nbsp;:</p></li><li><p>légères précipitations (environ 0,2 mm sur la journée)&nbsp;: sans effet sur la volatilisation des polluants&nbsp;;</p></li><li><p>températures faibles stables durant le temps de prélèvement (entre 8,4 et 10 °C)&nbsp;: neutre vis-à-vis de la volatilisation des polluants&nbsp;;</p></li><li><p>pression stable élevée (entre 1018 et 1017 hPa)&nbsp;: défavorable à une volatilisation des polluants.</p></li></ul>",
    sampleLocationDefinition:
      "<p>La localisation des prélèvements a été définie suite à la visite des locaux et au renseignement du questionnaire de relevé de mesures joint en Annexe.</p>",
    supportDescription:
      "<p>Les supports utilisés étaient des charbons actifs ou tout autre support, appartenant au même lot, adaptés à la recherche des composés identifiés dans les sols et/ou les eaux souterraines du site et susceptibles d’être présents dans l’air ambiant.</p><p>Ci-après la méthodologie mise en œuvre pour la réalisation des prélèvements&nbsp;:</p><ul><li><p> Installation du support entre le tuyau de prélèvement et la pompe, et réalisation de l’échantillonnage.</p></li><li><p>Réalisation du prélèvement et établissement d’une fiche de prélèvement (les fiches de prélèvements sont présentées en Annexe X).</p></li><li><p>Contrôle du débit en début et fin de prélèvement.</p></li></ul><p>Les supports utilisés pour l’échantillonnage de l’air ambiant (tube XX) étaient composés de 2 couches en série, afin de s’assurer que les résultats mesurés sur la première couche (zone analytique) soient cohérents avec ceux mesurés sur la seconde couche (zone de contrôle).</p><p>Le débit appliqué pour la réalisation des prélèvements a été de X L/min pendant X min, soit un volume prélevé de X L. Ce débit et cette durée de prélèvement ont permis d’atteindre les LQ nécessaires pour comparer les résultats d’analyses aux valeurs de référence présentées dans le paragraphe tableau ci-après.</p>",
    ambientAirSamplingJustificationTitle: "Justifications de prélèvement",
    ambientAirAnalysisProgramTitle: "Programmes d'analyses",
    airWeatherFromPreviousDaysTitle:
      "Données météorologiques des jours précédents",
    ambiantAirConditioning:
      "<p>Les conditionnements des médias contenant l’air ambiant ont été effectués conformément aux méthodes d’échantillonnage sur les différents médias. Un étiquetage a permis l’identification de chaque échantillon prélevé (n° ouvrage, n° d’échantillon, date, localisation,...). Les échantillons ont été conservés au frais, à l’obscurité, dans des glacières, et remis le jour même au transporteur express pour envoi vers le laboratoire accrédité.</p><p>Un blanc de transport et de terrain, nommé XXX, pour l’air ambiant a été effectué : il s’agit d’un support de prélèvement n’ayant pas servi au prélèvement, appartenant au même lot de fabrication que les supports utilisés lors de la campagne, et ayant été transporté sur site et envoyé à l’analyse. Les mêmes composés que ceux recherchés pour les prélèvements d’air ambiant ont été analysés sur ce blanc.</p><p>Un témoin extérieur, nommé XXX, a également été réalisé : les concentrations à l’intérieur du bâtiment étant susceptibles d’être influencées par celles de l’air extérieur, un prélèvement à l’extérieur du bâtiment a été réalisé, pour définir la contribution de sources extérieures potentielles. Celui-ci a été mis en place à une hauteur de 1,5 m par rapport au sol, à une distance de XXXm du mur du bâtiment.</p>",
  },
  en: {
    ambientAirSamplingCampaignContext:
      "<ul><li><p>period of low precipitation (4.6, 0.2 and 2.6 mm on the previous 3 days). This precipitation around the building can induce a potential increase of the fluxes towards the indoor air of the building if the pollution is present below the building,</p></li><li><p>period of positive but low temperatures (temperatures between 3 and 9.9°C). These temperature conditions are considered neutral for the volatilization of soil gases (temperatures &gt; 10°C favoring volatilization);</p></li><li><p> high pressure period (above 1013.25 hPa). These high atmospheric pressure conditions are considered not favorable for soil gas volatilization.</p><p>On XX/XX/20XX, the day of soil gas sampling, the following conditions were noted:</p></li><li><p>light precipitations (approximately 0,2 mm on the day): without effect on the volatilization of the pollutants;</p></li><li><p>stable low temperatures during the sampling period (between 8.4 and 10 °C): neutral with respect to the volatilization of pollutants;</p></li><li><p>stable high pressure (between 1018 and 1017 hPa): unfavorable to pollutant volatilization.</p></li></ul>",
    sampleLocationDefinition:
      "to the visit of the premises and the completion of the questionnaire of measurements attached in the Appendix.",
    supportDescription:
      "<p>The supports used were activated carbons or any other support, belonging to the same batch, adapted to the research of the compounds identified in the soils and/or groundwater of the site and likely to be present in the ambient air.</p><p>Hereafter the methodology implemented for the realization of the samplings:</p><ul><li><p>Installation of the support between the sampling pipe and the pump, and realization of the sampling.</p></li><li><p>Sampling and drawing up of a sampling form (the sampling forms are presented in Appendix X).</p></li><li><p>Check the flow rate at the beginning and end of the sampling.</p></li></ul><p>The media used for the ambient air sampling (XX tube) were composed of 2 layers in series, in order to ensure that the results measured on the first layer (analytical zone) were consistent with those measured on the second layer (control zone).</p><p>The flow rate used for the sampling was X L/min for X min, i.e. a sampled volume of X L. This flow rate and sampling time made it possible to reach the QLs necessary to compare the analytical results with the reference values presented in the following table.</p>",
    ambientAirSamplingJustificationTitle: "Sampling justification ",
    ambientAirAnalysisProgramTitle: "Analysis program",
    airWeatherFromPreviousDaysTitle: "Weather data from previous days",
  },
};
