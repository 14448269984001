export const vulnerabilityMessages = {
  fr: {
    BSS: "Banque de données du sous-sol",
    IGN: "Carte IGN au 1/25 000ème",
    NORTH: "Nord",
    NORTH_EAST: "Nord-Est",
    EAST: "Est",
    SOUTH_EAST: "Sud-Est",
    SOUTH: "Sud",
    SOUTH_WEST: "Sud-Ouest",
    WEST: "Ouest",
    NORTH_WEST: "Nord-Ouest",
    BSS_NUMBER: "N° BSS",
    SITE_DISTANCE: "Distance site (m)",
    POSITION: "Position",
    GEOLOGICAL_FORMATIONS: "Formations Géologiques",
    TOP_LAYER: "Haut de couche",
    BOTTOM_LAYER: "Bas de couche",
    NATURE: "Nature",
  },

  // create en messages
  en: {
    BSS: "Subsoil database",
    IGN: "IGN map at 1/25 000th",
    NORTH: "North",
    NORTH_EAST: "North-East",
    EAST: "East",
    SOUTH_EAST: "South-East",
    SOUTH: "South",
    SOUTH_WEST: "South-West",
    WEST: "West",
    NORTH_WEST: "North-West",
    BSS_NUMBER: "BSS number",
    SITE_DISTANCE: "Site distance (m)",
    POSITION: "Position",
    GEOLOGICAL_FORMATIONS: "Geological Formations",
  },
};
