export const fieldMeasurementsAndObservationsDefaultValues = {
  fr: {
    interpretationIntroduction:
      "<p>Ce paragraphe porte sur les investigations sur les milieux menées dans le cadre de la présente étude. La synthèse des investigations réalisées ainsi que le recensement des écarts entre les investigations effectivement réalisées et le programme prévisionnel d’investigations sont présentés dans le paragraphe XXX .</p>",
    soilObservations:
      "<p>Les formations géologiques rencontrées lors de la réalisation des sondages sont les suivantes :</p><ul><li><p>Terre végétale sur 20 cm ;</p></li><li><p>Sables limoneux jusqu’environ 2 m de profondeur.</p></li></ul><p>ou</p><p>Les tas de terres excavées étaient principalement constitués de remblais sablo-graveleux…</p><p>Aucun niveau humide n’a été relevé lors de la campagne d’investigations ou Des niveaux humides ont été rencontrés sur les sondages X à une profondeur de X m.</p><p>Aucune odeur ou trace suspecte n’a été identifiée sur les sondages réalisés ou Des odeurs de type (…huiles, fioul, solvant …) / des traces (…noires, grisâtres, huileuses …) ont été identifiées sur les sondages X à une profondeur de X m.</p><p>Ces constats sont cohérents/ ne sont pourtant pas cohérents avec les détections de COV ,mesurées au moyen d'un detecteur à photo-ionisation portatif :</p><p>Les mesures de teneurs élevés, comprises entre XX et XX ppm sur les échantillons X ou teneurs nulles ou jugées faibles (inférieures à XX ppm) sur l'ensemble des échantillons prélevés.</p>",
    groundwaterObservations:
      "<p>Les eaux prélevées présentaient un léger trouble et des couleurs grises en PZ3 et PZ4 et étaient claires en PZ1 et PZ2.</p><p>Des mesures ponctuelles sur site de la température, du pH et de la conductivité ont été réalisées.</p><p>Les résultats de ces mesures, sont présentés ci-après.</p>",
    superficialWaterObservations:
      "<p>Les eaux prélevées présentaient un léger trouble et des couleurs grises.</p><p>Des mesures ponctuelles sur site de la température, du pH et de la conductivité ont été réalisées.</p><p>Les résultats de ces mesures, sont présentés ci-après.</p>",
    piezometricLevelsMeasurements:
      "<p>Les niveaux piézométriques ont été mesurés sur site par nos soins sur chaque ouvrage au moyen d’une sonde à interface SILEX/Solinst préalablement aux prélèvements.</p><p>Les altitudes et profondeurs d'eau ont été mesurées par rapport au sommet du tubage PVC/PEHD des ouvrages ou autre se conformer au protocole de prélèvement.</p><p>Les résultats de ces mesures sont présentés dans le tableau ci-après.</p>",
    soilGasesObservations:
      "<p>Les mesures ponctuelles de COV totaux sur les gaz du sol ont été effectuées par photoionisation (PID). Des teneurs en COV ont été mesurés sur XX et XX avec des concentrations comprises entre XX et XX ppm.</p>",
    ambiantAirObservations:
      "<p>Les mesures ponctuelles de COV totaux sur l'air ambiant ont été effectuées par photoionisation (PID). Des teneurs en COV ont été mesurés sur XX et XX avec des concentrations comprises entre XX et XX ppm.</p>",
    tapWaterFoodstuffObservations:
      "<p>Lors de la campagne de prélèvement, il a été observé…. Par ailleurs des mesures ponctuelles sur l'eau du robinet sur site de la température, du pH et de la conductivité ont été réalisées. Les résultats de ces mesures, sont présentés dans le tableau ci-après :</p>",
    excavatedGroundObservationsMeasurements:
      "<p>Les formations géologiques rencontrées lors de la réalisation des sondages sont les suivantes :</p><ul><li><p>Terre végétale sur 20 cm ;</p></li><li><p>Sables limoneux jusqu’environ 2 m de profondeur.</p></li></ul><p>ou</p><p>Les tas de terres excavées étaient principalement constitués de remblais sablo-graveleux…</p><p>Aucun niveau humide n’a été relevé lors de la campagne d’investigations ou Des niveaux humides ont été rencontrés sur les sondages X à une profondeur de  X m.</p><p>Aucune odeur ou trace suspecte n’a été identifiée sur les sondages réalisés ou Des odeurs de type (…huiles, fioul, solvant …) / des traces (…noires, grisâtres, huileuses …) ont été identifiées sur les sondages X à une profondeur de X m.</p><p>Ces constats sont cohérents/ ne sont pourtant pas cohérents avec les détections de COV ,mesurées au moyen d'un detecteur à photo-ionisation portatif :</p><p>Les mesures de teneurs élevés, comprises entre XX et XX ppm sur les échantillons X ou teneurs nulles ou jugées faibles (inférieures à XX ppm) sur l'ensemble des échantillons prélevés.</p>",
  },
  en: {
    interpretationIntroduction:
      "<p>This paragraph deals with the environmental investigations conducted as part of this study. The summary of the investigations carried out as well as the identification of the discrepancies between the investigations actually carried out and the provisional investigation program are presented in paragraph XXX.</p>",
    soilObservations:
      "<p>The geological formations encountered during the drilling are the following:</p><ul><li><p>Topsoil on 20 cm ;</p></li><li><p>Silty sands to about 2 m depth.</p></li></ul><p>or</p><p>The heaps of excavated soil were mainly made up of sandy-gravelly fill...</p><p>No wet levels were found during the investigation campaign or Wet levels were encountered on the X borings at a depth of X m.</p><p>No suspicious odors or traces were identified on the borings performed or Odors of type (...oils, fuel oil, solvent ...) / traces (...black, grayish, oily ...) were identified on the X borings at a depth of X m. </p><p>These findings are consistent/are however not consistent with the detections of VOCs ,measured with a portable photoionization detector:</p><p>Measurements of high levels, between XX and XX ppm on the X samples or no levels or levels deemed low (below XX ppm) on all the samples taken.</p>",
    groundwaterObservations:
      "<p>The sampled water exhibited slight cloudiness and gray colors in PZ3 and PZ4 and was clear in PZ1 and PZ2.</p><p>On-site spot measurements of temperature, pH, and conductivity were performed.</p><p>The results of these measurements, are presented below.</p>",
    superficialWaterObservations:
      "<p>Sampled water exhibited slight cloudiness and gray colors.</p><p>On-site spot measurements of temperature, pH, and conductivity were performed.</p><p>The results of these measurements, are presented below.</p>",
    piezometricLevelsMeasurements:
      "</p><p>Piezometric levels were measured on site by us at each structure using a SILEX/Solinst interface probe prior to sampling.</p><p>The water elevations and depths were measured relative to the top of the PVC/HDPE casing of the structures or otherwise comply with the sampling protocol.</p><p>The results of these measurements are presented in the table below.</p>",
    soilGasesObservations:
      "</p>The point measurements of total VOCs on soil gases were made by photoionization (PID). VOC levels were measured on XX and XX with concentrations between XX and XX ppm.</p>",
    ambientAirObservations:
      "<p>Total VOC point measurements on ambient air were performed by photoionization (PID). Des teneurs en COV ont été mesurés sur XX et XX avec des concentrations comprises entre XX et XX ppm.</p>",
    tapWaterFoodstuffObservations:
      "<p>During the sampling campaign, it was observed.... In addition spot measurements on the tap water on site of temperature, pH and conductivity were performed. The results of these measurements, are presented in the table below:</p>",
    excavatedGroundObservationsMeasurements:
      "<p>The geological formations encountered during the drilling are the following:</p><ul><li><p>Topsoil on 20 cm ;</p></li><li><p>Silty sands to about 2 m depth.</p></li></ul><p>or</p><p>The heaps of excavated soil were mainly made up of sandy-gravelly fill...</p><p>No wet levels were found during the investigation campaign or Wet levels were encountered on the X borings at a depth of X m.</p><p>No suspicious odors or traces were identified on the borings performed or Odors of type (...oils, fuel oil, solvent ...) / traces (...black, grayish, oily ...) were identified on the X borings at a depth of X m. </p><p>These findings are consistent/are however not consistent with the detections of VOCs ,measured with a portable photoionization detector:</p><p>Measurements of high levels, between XX and XX ppm on the X samples or no levels or levels deemed low (below XX ppm) on all the samples taken.</p>",
  },
};
