export const siteVisitDefaultValues = {
  fr: {
    visitContributors:
      "intervenant_SOCOTEC - Fonction, accompagné de nom accompagnateur - fonction_accompagnateur de la Société X. Au cours de cette visite, des informations ont été recueillies par le témoignage de XXXXX.",
    siteOccupiedBy: "type d’activité recensée.",
    summaryBuildingsDescription:
      "<p>Il se compose de X bâtiments :</p><ul><li><p>un bâtiment à usage de XXX, situé au XXX du site d’une surface de XX m² au sol et comprenant x étage(s), un sous-sol ou vide sanitaire et chauffé par du XX&nbsp;;</p></li><li><p>un bâtiment secondaire exploité pour XXX…</p></li></ul><p>Les espaces extérieurs sont principalement occupés par des espaces de stationnement et voirie sur enrobé, des zones d’espaces verts…</p>",
    buildingsDescription:
      "<p>Il se compose de X bâtiments :</p><ul><li><p>un bâtiment à usage de XXX, situé au XXX du site d’une surface de XX m² au sol et comprenant x étage(s), un sous-sol ou vide sanitaire et chauffé par du XX&nbsp;;</p></li><li><p>un bâtiment secondaire exploité pour XXX…</p></li></ul><p>Les espaces extérieurs sont principalement occupés par des espaces de stationnement et voirie sur enrobé, des zones d’espaces verts…</p>",
    summaryFacilitiesAtRisk:
      "<p>Au cours de la visite, des installations à risque ont été identifiées :</p><ul><li><p>une cuve de stockage de fioul domestique, enterrée, de XX m3 située XXXX&nbsp;;</p></li><li><p>un transformateur électrique susceptible de contenir des huiles diélectriques PCB&nbsp;;</p></li><li><p>un stockage de déchets métalliques sur terrain nu…</p><p>ou</p><p>Aucune installation à risque, vis-à-vis d’une potentielle contamination des milieux, n’a été identifiée au cours de la visite.</p><p>Les zones suivantes n’ont pas été accessibles lors de la visite sur site.</p></li><li><p>&gt;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ….</p></li></ul><p>Les éléments relevés sont présentés sur le plan  et les photographies de visite  ci-après.</p>",
    facilitiesAtRisk:
      "<p>Au cours de la visite, des installations à risque ont été identifiées :</p><ul><li><p>une cuve de stockage de fioul domestique, enterrée, de XX m3 située XXXX&nbsp;;</p></li><li><p>un transformateur électrique susceptible de contenir des huiles diélectriques PCB&nbsp;;</p></li><li><p>un stockage de déchets métalliques sur terrain nu…</p><p>ou</p><p>Aucune installation à risque, vis-à-vis d’une potentielle contamination des milieux, n’a été identifiée au cours de la visite.</p><p>Les zones suivantes n’ont pas été accessibles lors de la visite sur site.</p></li><li><p>&gt;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ….</p></li></ul><p>Les éléments relevés sont présentés sur le plan  et les photographies de visite  ci-après.</p>",
    siteVisitPlanSource: "cadastre",
    nonAccessibleZonesDescription:
      "Les zones suivantes n'ont pas été accessibles lors de la visite de site. ",
    presenceOfPollution:
      "<p>Au cours de la visite de site, il n’a pas été repéré d’indice laissant supposer la présence de remblais divers ou de problème de pollution avéré.</p><p>ou</p><p>Au cours de la visite de site, il a été repéré XXXXXXXXXXXX laissant supposer la présence de remblais divers ou des problèmes de pollution avéré (traces d’écoulements, débordement de rétention…).</p>",
    neighborhoodDescription:
      "<p>Les usages suivants (et leur sensibilité associée) sont constatés aux abords du site (rayon de Xm) et présentés sur le plan ci-après</p><ul><li><p>Parcelles résidentielles privatives (maisons d’habitation avec jardin), en bordure est et nord – <em>usage sensible</em>&nbsp;;</p></li><li><p>Parcelles agricoles cultivées (cultures de XXX) à partir d’environ 50&nbsp;m au sud – <em>usage sensible</em>&nbsp;;</p></li><li><p>Activités tertiaires (banque XXX, bureaux de la société XXX) et artisanales en bordure est – <em>usage peu sensible</em>&nbsp;;</p></li><li><p>Activité industrielle…</p></li></ul>",
    overheadPhotographySource: "Open street map",
    neighborhoodSensibility:
      "De principe, le voisinage du site est considéré comme peu sensible compte tenu des usages recensés.",
    safetyCorrectiveMeasures:
      "<p>Aucun danger immédiat pour l’environnement et la santé publique n’ayant été identifié, il n’est pas nécessaire de mettre en œuvre de mesure corrective de mise en sécurité</p><p>ou</p><p>Compte tenu du danger immédiat pour l’environnement et la santé publique identifié, il est nécessaire de mettre en œuvre les mesures correctives de mise en sécurité suivantes&nbsp;:</p><ul><li><p>Cuve enterrée non utilisée&nbsp;: vidanger, dégazer et neutraliser la cuve enterrée de fuel</p></li><li><p>Bidons fuyards sur le sol&nbsp;: retirer les bidons / mise en place d’une rétention adaptée</p></li><li><p>Déchets stockés sans protection&nbsp;: enlever les déchets / mettre en place une protection</p></li><li><p>Mise en œuvre d’un diagnostic et/ou de surveillance et indiquer les milieux à investiguer</p></li></ul>",
    identificationOfOnsiteConstraints:
      "<p>Compte tenu des constats réalisés lors de la visite,</p><p>aucune contrainte particulière n’a été relevée au droit du site ou les contraintes suivantes ont été identifiées, et devront faire l’objet d’une vigilance accrue dans le cadre d’éventuelles investigations à réaliser sur site&nbsp;:</p><ul><li><p>Accessibilité des zones,</p></li><li><p>Présence d’infrastructures,</p></li><li><p>Présence de réseaux,</p></li><li><p>Locaux en sous-sols,</p></li><li><p>Chauffage au sol,</p></li><li><p>Servitudes,</p></li><li><p>Transformateur en activité….</p></li></ul>",
    visitPerimeter:
      "Lors de la visite de site, un questionnaire conforme au guide méthodologique 'visite du site' a été renseigné et est joint en Annexe.</p><p>L'emprise de la visite concerne l'ensemble du site décrit au paragraphe 3.1, ainsi que ses abords dans un rayon de 100 mètres.</p>",
  },
  en: {
    visitContributors:
      "<p>A visit to the site was carried out on XX/XX/XXXX by:</p><p>participant_SOCOTEC - Function, accompanied by name guide - function_guide of Company X. During this visit, information was collected by the testimony of XXXXX.</p><p>During the site visit, a questionnaire in accordance with the methodological guide 'Site visit' was completed and is attached in the Appendix.</p><p>The influence of the visit concerns the entire site described in paragraph 3.1, as well as its surroundings within a radius of Xm.</p><p></p>",
    siteOccupiedBy: "type of activity recorded.",
    summaryBuildingsDescription:
      "<p>It consists of X buildings:</p><ul><li><p>a building for use by XXX, located at XXX of the site with a surface area of XX m² on the ground and comprising x floor(s), a basement or crawl space and heated by XX&nbsp;;</p></li><li><p>a secondary building operated for XXX…</p></li></ul><p>Outdoor spaces are mainly occupied by parking spaces and roads on asphalt, green space zones...</p>",
    buildingsDescription:
      "<p>It consists of X buildings:</p><ul><li><p>a building for use by XXX, located at XXX of the site with a surface area of XX m² on the ground and comprising x floor(s), a basement or crawl space and heated by XX&nbsp;;</p></li><li><p>a secondary building operated for XXX…</p></li></ul><p>Outdoor spaces are mainly occupied by parking spaces and roads on asphalt, green space zones...</p>",
    summaryFacilitiesAtRisk:
      "<ul><li><p>an underground heating oil storage tank of XX m3 located XXXX&nbsp;;</p></li><li><p>an electrical transformer likely to contain dielectric oils PCB&nbsp;;</p></li><li><p>storage of metal waste on bare ground…</p><p>or</p><p>No risk installation, vis- regarding potential contamination of the environments, was identified during the visit.</p><p>The following areas were not accessible during the site visit.</p>< /li><li><p>&gt;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ….</p></li></ul><p>The elements noted are presented on the visit plan and photographs below.</p>",
    facilitiesAtRisk:
      "<ul><li><p>an underground heating oil storage tank of XX m3 located XXXX&nbsp;;</p></li><li><p>an electrical transformer likely to contain dielectric oils PCB&nbsp;;</p></li><li><p>storage of metal waste on bare ground…</p><p>or</p><p>No risk installation, vis- regarding potential contamination of the environments, was identified during the visit.</p><p>The following areas were not accessible during the site visit.</p>< /li><li><p>&gt;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ….</p></li></ul><p>The elements noted are presented on the visit plan and photographs below.</p>",
    siteVisitPlanSource: "cadastre",
    presenceOfPollution:
      "<p>During the site visit, no clues were found suggesting the presence of various backfills or a proven pollution problem.</p><p>or</p> <p>During the site visit, XXXXXXXXXXXX was identified, suggesting the presence of various backfills or proven pollution problems (traces of flow, retention overflow, etc.).</p>",
    neighborhoodDescription:
      "<ul><li><p>Private residential plots (dwelling houses with gardens), on the eastern and northern borders – <em>sensitive use</em>&nbsp;;</p></li> <li><p>Cultivated agricultural plots (crops of XXX) from about 50&nbsp;m to the south – <em>sensitive use</em>&nbsp;;</p></li><li><p >Tertiary activities (XXX bank, XXX company offices) and artisanal activities on the eastern edge – <em>insensitive use</em>&nbsp;;</p></li><li><p>Industrial activity…< /p></li></ul>",
    overheadPhotographySource: "Open street map",
    neighborhoodSensibility: "not very sensitive",
    safetyCorrectiveMeasures:
      "<p>As no immediate environmental and public health hazards have been identified, no safety corrective measures need to be implemented</p><p>or</ p><p>Given the immediate danger to the environment and public health identified, it is necessary to implement the following safety corrective measures:</p><ul><li><p> Unused underground tank: drain, degas and neutralize the underground fuel tank</p></li><li><p>Leaking canisters on the ground: remove the canisters / set up appropriate retention< /p></li><li><p>Waste stored without protection: remove the waste / put in place protection</p></li><li><p>Implementation of a diagnosis and /or surveillance and indicate the environments to be investigated</p></li></ul>",
    identificationOfOnsiteConstraints:
      "<p>In view of the observations made during the visit,</p><p>no particular constraint has been identified at the right of the site or the following constraints have been identified, and will have to be the subject of increased vigilance in the context of any investigations to be carried out on site: </p><ul><li><p>Accessibility of areas,</p></li><li><p>Presence of infrastructure,</p></li><li><p> Presence of networks,</p></li><li><p>Basement premises,</p></li><li><p>Underfloor heating,</p></li> <li><p>Easements,</p></li><li><p>Transformer in operation….</p></li></ul>",
  },
};
