export const historicalStudyDefaultValues = {
  fr: {
    dataSourceOfPersonsMet: "Personnes rencontrées :",
    consultedDocumentsOfPersonsMet: "/",
    consultedDocumentsOfCityHall:
      "PLU / Permis de construire / Permis de démolir / PC n° en date du",
    consultedDocumentsOfMunicipalArchives: "Docs (PC, plans, photos,…)",
    consultedDocumentsOfDepartmentalArchives:
      "Dossier ICPE en date du Référence",
    consultedDocumentsOfBasiasBasol: "SIS DOC PC Fiches BASIAS / BASOL / ...",
    consultedDocumentsOfNationalGeographicInstitute: "Photographies aériennes",
    consultedDocumentsOfAira: "Infos",
    consultedDocumentsOfDrealDdpp:
      "Dossier de déclaration/enregistrement/autorisation n° en date du",
    informationTypeOfOthers: ".../...",
    consultedDocumentsOfOthers: ".../...",
    informationCollectedDuringInterviews:
      "<p>Les informations suivantes ont été recueillies lors d’entretiens avec les populations riveraines, les employés actuels ou anciens du site, les propriétaires…&nbsp;:</p><ul><li><p>XXX</p></li></ul><p>Ou </p><p>Sans objet.</p>",
    photographsOrPlans:
      "de photographies aériennes anciennes et d’anciens plans",
    analysisPhotographsOldPlans:
      "Analyse des photographies aériennes et des anciens plans (source : XXX)",
    basiasBasolSitesDescription:
      "<p>D’après les informations obtenues auprès des sources consultées, le site a accueilli :</p><p>les sites BASIAS et ou BASOL suivants et correspond à un SIS&nbsp;:</p><ul><li><p>Site BASIAS référencé XXXXX, enregistré sous la raison sociale XXXX pour une activité de XXXX entre XXXX et XXXX&nbsp;;</p></li><li><p>Site BASOL référencé XXXXX, enregistré sous la raison sociale XXXX pour une activité de XXXX ayant fait l’objet d’une dépollution / surveillance…</p></li><li><p></p></li></ul><p>ou</p><p>D’après les informations obtenues auprès des sources consultées, le site n’a accueilli aucun site BASIAS ou BASOL et ne fait pas partie d’un SIS.</p>",
    inventoryOfIncidents:
      "<p>D’après les informations obtenues, aucun incident ou accident ayant pu avoir des conséquences environnementales (déversement, fuites, …) n’a été répertorié sur le site.</p><p>ou</p><p>Des incidents / accidents sont répertoriés sur le site. Ces évènements ainsi que leurs conséquences environnementales présumées ou constatées sont détaillés ci-après&nbsp;:</p><ul><li><p>Bombardements, déversement accidentel, fuite, incendie…</p></li></ul>",
    contraintsImposedThroughUsageRestrictions:
      "<p>Sur la base des documents consultés, le site n’est a priori pas concerné par des contraintes qui sont imposées sur le site par le biais de restriction d’usage (Servitudes d’utilités Publiques, Projet d’Intérêt Général, autres mécanismes de restriction d’usage dont les éventuelles conventions de droit privé annexés aux actes de vente) ou est concerné par ….</p><p>Par ailleurs, l’acte de vente n’ayant pas été consulté, la possible présence de servitudes de droit privé n’est pas à exclure.</p>",
    industrialActivitiesDescription:
      "<p>Les bases de données GEORISQUES/BASIAS et BASOL ont été consultées afin d’identifier les anciens sites industriels, à proximité du site.</p><p>Ces bases de données ont permis d’identifier XX activités industrielles à risques dans un périmètre de XX m aux abords du site d'étude</p><p>Celles-ci sont listées dans le tableau suivant et localisées ci-après.</p>",
    presentationBasiasBasolTitle:
      "Présentation des sites BASIAS et BASOL situés dans un rayon de XX m",
    basolSiteType: "BASOL",
    basolReference: "(1) 44.xxxx",
    basolCompanyName: "xx",
    basolAddress: "ZI de XXX 150 m à l’est du site",
    basolActivitiesDates: "1982 – 1999 Usine de XXX",
    basolRemark:
      "Mise en évidence de contaminations des eaux souterraines et des sols par des hct – Site traité, sous surveillance",
    basiasSiteType: "BASIAS",
    basiasReference: "(2) PAL44xxx",
    basiasCompanyName: "xx",
    basiasAddress: "Rue de 95 m au sud du site",
    basiasActivitiesDates: "1952 – date de fin inconnue Station-service",
    basiasRemark: "Site réaménagé en parkin",
    locationBasiasTitle:
      "Localisation des sites BASIAS/BASOL et activités à risques situés à proximité du site d’étude (rayon de XX m) (Source : Infoterre)",
    riskyActivitesConclusion:
      "<p>Compte tenu de la distance / proximité des installations recensées, de la nature des activités réalisées et de leurconfiguration…, le risque de transfert d’une éventuelle contamination issue de ces sites vers le site d’étude estjugée non négligeable. </p>",
    synthesisHistorical:
      "<p>Sur la base des informations collectées, aucune activité ou installation potentiellement polluante, susceptible d’avoir impacté la qualité des milieux au droit du site, n’a été recensée.</p><p>ou</p><p>Les activités ou installations potentiellement polluantes actuelles ou passées, et toutes pratiques (gestion des déchets, rejets maîtrisés ou non, etc…) pouvant être à l’origine d’une pollution potentielle des milieux sont recensées dans le tableau ci-après et sont localisées sur le plan .</p>",
    locationActivitiesTitle: "Plan de localisation des sources de pollution",
    photographsPlansAnalysisTitle: "Analyse des photographies aériennes",
    productUsedAndRawMaterialTitle: "Matières premières et produits utilisés",
    siteSourceOfContaminationTitle:
      "Sources potentielles de contamination du site",
    usedProductAndWasteTitle:
      "Liste des produits usagés et déchets générés sur le site",
    wasOrNotIndexed:
      "<p>Par ailleurs, le site XXXX classé au titre de la réglementation relative aux installations classées pour l’environnement.</p>",
    activitiesFromNowAndPast:
      "<p>Les activités et procédés actuels ou passés sur le site, connus d’après les sources d’informations consultées, sont répertoriés dans le tableau ci-après :</p>",
    productUsedOnTheSite:
      "<p>Les produits utilisés sur le site sont répertoriés dans le tableau suivant :</p>",
    usedProductAndWaste:
      "<p>Les produits usagés et déchets générés sur le site sont répertoriés dans le tableau suivant :</p>",
  },
  en: {},
};
