export const vulnerabilityStudyDefaultValues = {
  fr: {
    geographicLocation:
      "Le site est implanté dans une zone urbaine, d’activité industrielle : XXX, dans la partie XX de la commune de XX, dans le département de XX.",
    topographicLocation:
      "Il présente une topographie globalement plane ou une pente d’environ XX %, son altitude s’équilibrant à environ XX m NGF.",
    nearBssWorksDescription:
      "<p>Le site InfoTerre du BRGM répertorie XX ouvrage(s) de la Banque de Données du Sol et du Sous-sol (Bss) situés à proximité du site (rayon d’environ XX m) sur la même formation géologique.</p>",
    bssPointsTitle: "Localisation des points BSS dans un rayon de XX m.",
    averageLithologicSection:
      "<ul><li>De 0 à X m : (formation géologique)</li><li>De X à X m : (formation géologique)</li><li>De X à X m : (formation géologique)</li></ul>",
    hydrogeologicalContext:
      "<p>Les formations en présence sont le siège d’une nappe XXX qui correspond à la masse d'eau n° XXX qui s'étend de XXX.</p><p>Cette masse d'eau est définie par les caractéristiques hydrodynamiques suivantes :</p><ul><li>vitesses d’écoulement de m/h,</li><li>perméabilités comprises entre m/s.</li></ul><p>Cette aquifère est libre ou captif et en relation directe ou non avec les rivières de XX, influencé par les marées.</p><p>La nappe est alimentée à l’affleurement par l’infiltration des précipitations, ainsi que par les échanges avec les eaux superficielles décrites ci-après.</p><p>Au droit du site, sa profondeur est comprise entre XX m et XX m et son sens d'écoulement est dirigé globalement vers XXX .</p>",
    indicatedWaterDepth:
      "Le(s) ouvrages présents au droit du site indique(nt) une profondeur d’eau de XX m.",
    hydrogeologicalFloodingRisk:
      "Le site présente des risques d’inondation par remontée de nappe. ",
    hydrogeologicalContextConclusion:
      "<p>Considérant la grande profondeur supposée des eaux souterraines et l'absence de couverture peu perméable qui la séparerait de la surface, les eaux souterraines sont considérées comme vulnérables.</p>",
    hydrologicalContext:
      "<p>Le site est implanté à environ X m à / au (S, N, E, W) de la rivière / du lac XX</p><p>Le débit de la rivière/du lac est de l'ordre de XX m3/h.</p><p>Les informations disponibles sur la qualité des eaux superficielles montrent que ces eaux sont de bonne ou mauvaise qualité en lien avec une pollution en provenance de XX ..</p>",
    hydrologicalFloodingRisk:
      "Au regard du site GEORISQUES, le site d'étude se trouve/ne se trouve pas dans une zone de risque d'inondation",
    surfaceWaterVulnerability:
      "Considérant la présence au droit ou à proximité immédiate ou à XX m du site d’eaux superficielles, ces dernières sont considérées comme vulnérables/non vulnérables.",
    onSiteGroundSurfacesDescription:
      "<p>Le site comprend des surfaces imperméabilisées (enrobé, béton ou bâtiment) sur environ X % de sa surface.</p><p>Sur le reste de sa surface, le site présente des surfaces non imperméabilisées: sols nus, espaces verts...</p><p>La présence d'activités ou de stockages sur terrain nu a/n'a pas été observée.</p><p> Les activités exercées sur terrain nu sont les suivantes : XXX</p><p>Aucun indice d’écoulement superficiel n’a par ailleurs été mis en évidence ou Des indices d’écoulements sont observés au niveau de XX.</p>",
    offSiteGroundSurfacesDescription:
      "<p>Au voisinage du site, des surfaces non imperméabilisées sont présentes (espaces verts et jardins potagers), …</p><p>La présence de stockages ou d’activités potentiellement polluantes au droit de ces zones a pu / n’a pas pu être identifiée / est identifiée. Les activités identifiées sont les suivantes : XXX.</p>",
    climateDescription:
      "<p>Le climat de Ville est de type continental, avec des hivers froids et des été chauds.</p><p></p> La température moyenne du mois le plus froid, janvier est de 0°C, celle du mois le plus chaud juillet est de 0°C.",
    climateDetails:
      "<p>Les précipitations s’élèvent à XX mm mm par an en moyenne, le printemps et l’automne étant les périodes les plus pluvieuses.</p><p>En moyenne la ville de XXX connaît XX jours de pluie par an et XX jours d’ensoleillement.</p><p>Les mois de décembre à février sont les plus concernés par la chute de neige (en moyenne 18,6 jours de neige par an) et le gel.</p><p>Des vents importants en provenance du Nord et du Sud soufflent régulièrement tout au long de l’année, avec des maximums en hiver (ou plus largement de l’automne à février) ainsi que lors d’orages en juillet. Les vents de Nord sont prédominants et les vents d’Est et d’Ouest sont peu représentés.</p>",
    landUse:
      "<p>Le site est implanté dans une zone industrielle/tertiaire, notée XXX au Plan Local d'Urbanisme de XXXX</p><p>Cette zone correspond à ....</p><p>L'environnement du site comporte en grande partie des habitations individuelles et collectives et des activités industrielles et artisanales.</p>",
    groundwaterUses:
      "<p>Les données de XXX répértorient X captages d'eaux souterraines à XXX et ses communes limitrophes (situées dans un rayon de XX km autour du site).</p><p>Par ailleurs, Info Terre répértorie XX captages dans un rayon de XX m autour du site.</p><p>Ces captages sont présentés dans le tableau suivant et leur localisation précisée après.</p>",
    sensitiveGroundwaterUses:
      "<p> Parmi les captages recensés, seuls XX sont exploités pour des usages dits sensibles (AEP, Irrigation, Puits privé, Abreuvage..), ils sont situés entre XX et XX m autour du site d'étude</p><p>Au regard des données de l’Agence Régionale de Santé (ARS), le site n’est implanté dans aucun périmètre de protection de champ captant ou est implanté dans le périmètre (éloigné, rapproché) du champ captant XX,</p>",
    groundWaterUsesConclusion:
      "<p>Du fait de l’utilisation ou de l’absence d’utilisation des eaux souterraines pour des usages d’AEP/Agricoles/piscine dans un rayon de XX m autour du site, celles-ci sont considérées comme sensibles/non sensibles.</p>",
    surfaceWaterUses:
      "<p>L’Agence de l’Eau/ Autre XX ne répertorie aucun captage ou répertorie X captages d’eaux superficielles sur la commune de XXX et dans ses communes limitrophes (situées dans un rayon de 0 km autour du site).</p><p>Le captage le plus proche se situe à environ XX m du site et est utilsé pour : AEP, agricole, industriel.</p>",
    surfaceWaterUsesConclusion:
      "<p> Par ailleurs, des acivités de loisirs et de pêche sont pratiquées dans la rivière XX située à 0 km du site.</p><p>En raison des usages des eaux superficielles, celles-ci sont considérées comme sensibles.</p>",
    protectedAreas:
      "<p> Le site est situé au droit d'une zone à enjeux naturels: il s'agit de XXX.</p> <p>Les zones à enjeux naturels les plus proches du site d'étude, identifées dans un rayon de X km autour du site d'étude. Il s'agit de :</p><ul><li>Un(e) catégorie, enregistré(e) sous la référence XXXXX au nom de XXXX, situé(e) à environ XX m au XX du site d’étude ;</li></ul>",
    protectedAreasConclusion:
      "<p>Du fait de la présences de ces zones protégées au droit du site ou à proximité, d’habitations et d’infrastructures publiques à proximité immédiates du site, son environnement est considéré comme sensible.</p>",
    monitoringStructuresIdentification:
      "<p>D’après les constats effectués lors de la visite de site et l’examen de l’ensemble des sources et documents consultés, aucun ouvrage de surveillance n’a été identifié sur le site ou à proximité.</p><p>ou</p><p>D’après les constats effectués lors de la visite de site et l’examen de l’ensemble des sources et documents consultés, le site comprend XX ouvrage de surveillance ou XX ouvrage(s) de surveillance ont été identifiés au voisinage du site. Il s’agit des ouvrages suivants, dont la localisation est présentée ci-aprés:</p>",
    exposureRoutesIdentification:
      "<p>Aucune source de contamination des milieux n’étant suspectée, il n’est considéré aucune exposition potentielle des actuels / futurs usagers du site à une contamination des milieux.</p><p>Ainsi, en l’absence de source potentielle de contamination, le schéma conceptuel de site (établissant les relations entre sources potentielles de contamination, voies de transfert et voies d’exposition sur site et hors site) n’a pas lieu d’être.</p><p>ou</p><p>Des sources de contamination peuvent être suspectées dans les milieux souterrains du fait de la présence actuelle et/ou ancienne d’installations, activités et/ou zones à risque précitées.</p><p>Considérant les aménagements actuels ou prévus, les voies de transfert envisageables correspondent à des transferts par : <ul><li>volatilisation d’éventuels polluants volatils,</li><li>migration de polluants dans les eaux souterraines et/ou superficielles,</li><li>ingestion directe de sols et contact cutané, </li><li>ingestions de fruits et légumes, d’eau ou de denrées alimentaires,</li><li>envols de poussières de sols.</li></ul><p><p>Par conséquent, les milieux suivants peuvent constituer des milieux d’exposition pour les usagers actuels et / ou futurs : les sols superficiels, l’air ambiant (intérieur et extérieur), les eaux superficielles et/ou souterraines sur site et hors site.</p><p>Considérant l’usage actuel / futur du site, les cibles retenues sont constituées d’une population sensible (résidents adultes et enfants,…), et/ou d’une population peu sensible (travailleurs,…).</p>",
    groundAndLandToExcavateOnSiteUses:
      "Exploitation industrielle/ Habitations / Commerces / Bureaux",
    groundAndLandToExcavateOffSiteUses:
      "Zone résidentielle / industrielle/ urbaine / agricole",
    groundAndLandToExcavateEnvironmentToRemember:
      "<p>A retenir pour des investigations.</p><p> Source potentielle et première voie de transfert de la pollution éventuelle<p>",
    undergroundWaterOnSiteUses:
      "Prélèvement pour un usage industriel ou autre sur le site / Absence d’usage sur site",
    undergroundWaterOffSiteUses:
      "Absence de captage dit sensible dans le voisinage direct / Présence d’un captage AEP à moins de 150 m du site",
    undergroundWaterEnvironmentToRemember:
      " <p>Aretenir pour des investigations ou Non retenu à ce stade de l’étude</p><p>Indiquer les raisons</p><p>Ex : Source potentielle extérieure au site et voie de transfert</p>",
    superficialWaterOnSiteUses:
      "Prélèvement pour un usage industriel / agricole ou autre sur le site / Absence d’usage d’eaux superficielles sur site",
    superficialWaterOffSiteUses:
      "Absence d’usage sensible à proximité / Absence de voie de transfert directe / Captage aval utilisée pour l’irrigation",
    superficialWaterEnvironmentToRemember:
      "<p>A retenir pour des investigations ou Non retenu à ce stade de l’étude</p><p>Indiquer les raisons</p>",
    groundGasesAmbiantAirSedimentOnSiteUses:
      "Inhalation d’air ambiant intérieur / extérieur / Ingestion de poussière",
    groundGasesAmbiantAirSedimentOffSiteUses:
      "Inhalation extérieure négligeable du fait de la dilution dans l’air",
    groundGasesAmbiantAirSedimentEnvironmentToRemember:
      "<p>A retenir pour des investigations ou Non retenu à ce stade de l’étude</p><p>Indiquer les raisons</p>",
    foodstuffsTapWaterOnSiteUses: "Domestique / agricole",
    foodstuffsTapWaterOffSiteUses: "Domestique / agricole",
    foodstuffsTapWaterEnvironmentToRemember:
      "<p>A retenir pour des investigations ou Non retenu à ce stade de l’étude</p><p>Indiquer les raisons</p>",
    siteGeologicalImplantation:
      "<p>L'examen de la carte géologique de la region de XXXX et de sa notice montre que le site est implanté sur XXXX</p>",
  },
  en: {
    geographicLocation:
      "The site is located in an urban area, of industrial activity: XXX, in the XX part of the commune of XX, in the department of XX.",
    topographicLocation:
      "It has a generally flat topography or a slope of about XX%, its altitude balancing at about XX m NGF.",
    nearBssWorksDescription:
      "<p>The BRGM InfoTerre site lists XX work(s) from the Banque de Données du Sol et du Sous-sol (Bss) located in the vicinity of the site (radius of approximately XX m) on the same geological formation.</p>",
    bssPointsTitle: "Location of BSS points within XX m.",
    averageLithologicSection:
      "<ul><li>From 0 to X m : (geological formation)</li><li>From X to X m : (geological formation)</li><li>From X to X m : (geological formation)</li></ul>",
    hydrogeologicalContext:
      "<p>The formations in presence are the seat of a water table XXX which corresponds to the water mass n° XXX which extends from XXX.</p><p>This water mass is defined by the following characteristics hydrodynamic characteristics :</p><ul><li>flow speeds of m/h,</li><li>permeabilities between m/s.</li></ul><p>This aquifer is free or captive and in direct relation or not with the rivers of XX, influenced by the tides.</p><p>The water table is fed at the outcrop by the infiltration of precipitation, as well as by exchanges with surface water described below.</p><p>At the right of the site, its depth is between XX m and XX m and its direction of flow is directed globally towards XXX .</p>",
    indicatedWaterDepth:
      "The structure(s) present at the site indicate(s) a water depth of XX m.",
    hydrogeologicalFloodingRisk:
      "The site presents risks of flooding by rising water table.",
    hydrogeologicalContextConclusion:
      "<p>Considering the assumed great depth of groundwater and the lack of low permeability cover that would separate it from the surface, groundwater is considered vulnerable.</p>",
    hydrologicalContext:
      "<p> The site is located approximately X m to / at (S, N, E, W) of the river / lake XX</p><p>The flow of the river / lake is of the order of XX m3/h.</p><p>Available information on the quality of surface water shows that this water is of good or poor quality in connection with pollution from XX ..</p>",
    hydrologicalFloodingRisk:
      "With respect to the GEORISQUES site, the study site is/is not in a flood hazard area.",
    waterPointFlowRate: "XX m3/h",
    waterPointQuality:
      "good or bad quality in connection with pollution from XX or other...",
    surfaceWaterVulnerability:
      "Considering the presence of surface water at or near the site or within XX m of the site, it is considered vulnerable/not vulnerable",
    onSiteGroundSurfacesDescription:
      "<p>The site includes impervious surfaces (asphalt, concrete or building) on approximately X% of its surface.</p><p>On the rest of its surface, the site presents non-impervious surfaces: bare soil, green spaces...</p><p>The presence of activities or storage on bare ground has/have not been observed.</p><p>Activities carried out on bare ground are as follows: XXX</p><p>No evidence of surface runoff was otherwise evidenced or Evidence of runoff is observed at XX.</p>",
    offSiteGroundSurfacesDescription:
      "<p>In the vicinity of the site, non-impervious surfaces are present (green spaces and vegetable gardens), ...</p><p>The presence of storage or potentially polluting activities in line with these areas could / could not be identified / is identified. The activities identified are as follows: XXX.</p>",
    climateDescription:
      "<p>The climate of Ville is continental, with cold winters and hot summers.</p><p></p> The average temperature of the coldest month, January is 0°C, the average temperature of the warmest month July is 0°C.",
    climateDetails:
      "<p>Precipitation averages XX mm mm per year, with spring and autumn being the rainiest periods.</p><p>On average the city of XXX experiences XX days of rain per year and XX days of sunshine. </p><p>The months of December through February are most concerned with snowfall (an average of 18.6 days of snow per year) and frost.</p><p>Significant winds from the north and south blow regularly throughout the year, with maximums in winter (or more broadly from fall through February) as well as during thunderstorms in July. Northerly winds predominate, and easterly and westerly winds are poorly represented.</p>",
    landUse:
      "<p>The site is located in an industrial/tertiary zone, noted XXX in the Local Urban Plan of XXXX</p><p>This zone corresponds to ....</p><p>The site's environment consists largely of individual and collective housing and industrial and artisanal activities.</p>",
    groundwaterUses:
      "<p>The data from XXX locates X groundwater catchments in XXX and its bordering communes (located within XX km of the site).</p><p>In addition, Info Terre locates XX catchments within XX m of the site.</p><p>These catchments are presented in the following table and their locations specified afterwards.</p>",
    sensitiveGroundwaterUses:
      "<p>Among the identified catchments, only XX are exploited for so-called sensitive uses (AEP, Irrigation, Private well, Drinking.. ), they are located between XX and XX m around the study site</p><p>With regard to the data of the Regional Health Agency (ARS), the site is not located in any catchment field protection perimeter or is located in the perimeter (remote, close) of the catchment field XX,</p>",
    groundWaterUsesConclusion:
      "<p>Because of the use or lack of use of groundwater for WTP/Agricultural/pool uses within XX m of the site, it is considered sensitive/non-sensitive.</p>",
    surfaceWaterUses:
      "<p>The Water Agency/Other XX does not list any catchment or lists X surface water catchments in the commune of XXX and its bordering communes (located within a radius of 0 km around the site).</p><p>The nearest catchment is located approximately XX m from the site and is used for: AEP, agricultural, industrial.</p>",
    surfaceWaterUsesConclusion:
      "<p>In addition, recreational and fishing acivities are practiced in the XX river located 0 km from the site.</p><p>Due to the surface water uses, these are considered sensitive.</p>",
    protectedAreas:
      "<p>The site is located in an area with natural issues: it is XXX.</p><p>The closest natural zones to the study site, identified within a radius of X km around the study site. These are :</p><ul><li>A category, registered under the reference XXXXX in the name of XXXX, located at approximately XX m at XX of the study site;</li></ul>",
    protectedAreasConclusion:
      "<p>Due to the presence of these protected areas at or near the site, housing and public infrastructure in the immediate vicinity of the site, its environment is considered sensitive.</p>",
    monitoringStructuresIdentification:
      "<p>Based on the findings of the site visit and review of all sources and documents consulted, no monitoring structures were identified on or near the site.</p><p>or</p><p>Based on the findings of the site visit and review of all sources and documents consulted, the site includes XX monitoring structure(s) or XX monitoring structure(s) were identified in the vicinity of the site. These are the following structures, the location of which is presented below:</p>",
    exposureRoutesIdentification:
      "<p>Aucune source de contamination des milieux n’étant suspectée, il n’est considéré aucune exposition potentielle des actuels / futurs usagers du site à une contamination des milieux.</p><p>Ainsi, en l’absence de source potentielle de contamination, le schéma conceptuel de site (établissant les relations entre sources potentielles de contamination, voies de transfert et voies d’exposition sur site et hors site) n’a pas lieu d’être. </p><p>ou</p><p>Des sources de contamination peuvent être suspectées dans les milieux souterrains du fait de la présence actuelle et/ou ancienne d’installations, activités et/ou zones à risque précitées.</p><p>Considérant les aménagements actuels ou prévus, les voies de transfert envisageables correspondent à des transferts par : <ul><li>volatilisation d’éventuels polluants volatils,</li><li>migration de polluants dans les eaux souterraines et/ou superficielles,</li><li>ingestion directe de sols et contact cutané, </li><li>ingestions de fruits et légumes, d’eau ou de denrées alimentaires,</li><li>envols de poussières de sols. </li></ul><p><p>Par conséquent, les milieux suivants peuvent constituer des milieux d’exposition pour les usagers actuels et / ou futurs : les sols superficiels, l’air ambiant (intérieur et extérieur), les eaux superficielles et/ou souterraines sur site et hors site.</p><p>Considérant l’usage actuel / futur du site, les cibles retenues sont constituées d’une population sensible (résidents adultes et enfants,…), et/ou d’une population peu sensible (travailleurs,…).</p>",
    groundAndLandToExcavateOnSiteUses:
      "Exploitation industrielle/ Habitations / Commerces / Bureaux",
    groundAndLandToExcavateOffSiteUses:
      "Residential/ Industrial/ Urban/ Agricultural area",
    groundAndLandToExcavateEnvironmentToRemember:
      "<p>To be retained for investigations.</p><p>Potential source and first pathway of possible pollution transfer</p>",
    undergroundWaterOnSiteUses:
      "Withdrawal for industrial or other use on site / No use on site",
    undergroundWaterOffSiteUses:
      "Absence of a so-called sensitive water catchment in the direct vicinity / Presence of an AEP catchment within 150 m of the site",
    undergroundWaterEnvironmentToRemember:
      "<p>Retention for investigations or Not retained at this stage of the study</p><p>Indicate reasons</p><p>Ex: Potential off-site source and transfer route</p>",
    superficialWaterOnSiteUses:
      "Withdrawal for industrial / agricultural or other use on site / No surface water use on site",
    superficialWaterOffSiteUses:
      "No sensitive use nearby / No direct transfer path / Downstream catchment used for irrigation",
    superficialWaterEnvironmentToRemember:
      "<p>To be retained for investigations or Not retained at this stage of the study</p><p>Indicate reasons</p>",
    groundGasesAmbientAirSedimentOnSiteUses:
      "Inhalation of indoor/outdoor ambient air / Ingestion of dust",
    groundGasesAmbiantAirSedimentOffSiteUses:
      "Negligible outdoor inhalation due to dilution in air",
    groundGasesAmbiantAirSedimentEnvironmentToRemember:
      "<p>To be retained for investigations or Not retained at this stage of the study</p><p>Indicate reasons</p>",
    foodstuffsTapWaterOnSiteUses: "Domestic/Agricultural",
    foodstuffsTapWaterOffSiteUses: "Domestic / agricultural",
    foodstuffsTapWaterEnvironmentToRemember:
      "<p>To be retained for investigations or Not retained at this stage of the study</p><p>Indicate reasons</p>",
  },
};
