export const conclusionRecommendations = {
  fr: {
    conclusion:
      "Rappeler le contexte et les grandes lignes de la mission.\n" +
      "Faire une synthèse des contaminations éventuellement mises en évidence.",
    recommendations:
      "Présentation des suites à donner\n" +
      "Rappel des mesures d’urgence éventuelles",
  },
  en: {
    conclusion:
      "Recall the context and the main lines of the mission; summarise any contamination that may have been identified.",
    recommendations:
      "Presentation of the follow-up to be given Reminder of possible emergency measures",
  },
};
