export const sampleMessages = {
  en: {
    SOIL: "Soil",
    UNDERGROUND_WATERS: "Underground waters sampling",
    AMBIENT_AIR: "Ambient air sampling",
    SOIL_GAS: "Soil gas",
    EXCAVATED_SOIL: "Excavated soil",
    COMPOSITE: "Composite",
    ACTIVE: "Active",
    PASSIVE: "Passive",
    CANISTER: "Canister",
    UNITARY: "Unitary",
    LAYERED: "Layered",
    MACAOH: "MACAOH",
    INTERIOR: "Interior",
    EXTERIOR: "Exterior",
    PTFE: "PTFE",
    PEHD: "PEHD",
    PP: "PP",
    ACTIVATED_CARBON_400_200: "Activated carbon 400/200",
    ACTIVATED_CARBON_100_50: "Activated carbon 100/50",
    CARULITE_500: "Carulite 500",
    CARULITE_200: "Carulite 200",
    RADIELLO_130: "Radiello 130",
    RADIELLO_145: "Radiello 145",
    XAD2: "XAD2",
    INOX: "STAINLESS",
  },
  fr: {
    SOIL: "Sol",
    UNDERGROUND_WATERS: "Eaux souterraines",
    AMBIENT_AIR: "Air ambiant",
    SOIL_GAS: "Gaz des sols",
    EXCAVATED_SOIL: "Terres excavées",
    COMPOSITE: "Composite",
    ACTIVE: "Actif",
    PASSIVE: "Passif",
    CANISTER: "Canister",
    UNITARY: "Unitaire",
    LAYERED: "Stratifié",
    MACAOH: "MACAOH",
    INTERIOR: "Interieur",
    EXTERIOR: "Exterieur",
    PTFE: "PTFE",
    PEHD: "PEHD",
    PP: "PP",
    ACTIVATED_CARBON_400_200: "Charbon actif 400/200",
    ACTIVATED_CARBON_100_50: "Charbon actif 100/50",
    CARULITE_500: "Carulite 500",
    CARULITE_200: "Carulite 200",
    RADIELLO_130: "Radiello 130",
    RADIELLO_145: "Radiello 145",
    XAD2: "XAD2",
    INOX: "INOX",
  },
};
