export const materialMessages = {
  en: {
    PACK_DE_BASE: "Base pack",
    PACK_SOL_MARTEAU: "Soil hammer pack",
    PACK_SOL_FOREUSE: "Soil drill pack",
    PACK_EAUX_SOUTERRAINES: "Underground waters pack",
    PACK_GAZ_DE_SOLS_AIR_AMBIANT: "Soil gas/Ambient air pack",
    FORAGE: "Drill",
    POMPE: "Pump",
    PUMP: "Pump",
    PID: "PID",
    PHMETRE: "pHmeter",
    CONDUCTIMETRE: "Conductometer",
    DEBITMETRE: "Flowmeter",
    MANUAL: "Manual sampling",
    name: "Name",
    agencyUuid: "Agency",
    subAgency: "Sous agence",
    company: "Company",
    brand: "Brand",
    type: "Type",
    reference: "Reference",
    serialNumber: "Serial NUmber",
    actions: "Actions",
    matrix: "Matrix",
    packs: "Packs",
    typologies: "Typologies",
    designation: "Désignation",
    subcontractor: "Subcontractor company",
    rental: "Rental company",
  },
  fr: {
    PACK_DE_BASE: "Pack de base",
    PACK_SOL_MARTEAU: "Pack sol marteau",
    PACK_SOL_FOREUSE: "Pack sol foreuse",
    PACK_EAUX_SOUTERRAINES: "Pack eaux souterraines",
    PACK_GAZ_DE_SOLS_AIR_AMBIANT: "Pack gaz de sols/air ambiant",
    FORAGE: "Forage",
    POMPE: "Pompe",
    PUMP: "Pompe",
    PID: "PID",
    PHMETRE: "pHmètre",
    CONDUCTIMETRE: "Conductimètre",
    DEBITMETRE: "Débitmètre",
    MANUAL: "Préleveur manuel",
    name: "Nom",
    agencyUuid: "Agence",
    subAgency: "Sous agence",
    company: "Entreprise",
    brand: "Marque",
    type: "Type",
    reference: "Reference",
    serialNumber: "N° de série",
    actions: "Actions",
    matrix: "Matrice",
    packs: "Packs",
    typologies: "Typologies",
    designation: "Designation",
    subcontractor: "Société sous-traitante",
    rental: "Société de location",
  },
};
