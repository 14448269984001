export const purgeMessages = {
  en: {
    STATIC: "Static",
    DYNAMIC: "Dynamic",
    DIRECT_REJECT_TO_NETWORK: "Direct reject to a suitable network",
    DIRECT_REJECT_TO_EXIT: "Direct reject to an authorized exis",
    TREATMENT_BEFORE_REJECTION_TO_NETWORK:
      "On site treatment with a mobile filter (active coil) before rejecting to network",
    TREATMENT_BEFORE_REJECTION_TO_ENV:
      "On site treatment with a mobile filter (active coil) before rejecting to natural environment",
    ELIMINATION: "Disposal using a suitable route",
    DISPOSABLE_MANUAL_SAMPLER_PEHD: "Disposable manual sampler in PEHD",
    DISPOSABLE_SAMPLER_POLYPROPYLENE_STERILE:
      "Sterile disposable polypropylene sampler",
    INTERFACE_PROBE: "interface probe",
  },
  fr: {
    STATIC: "Statique",
    DYNAMIC: "Dynamique",
    DIRECT_REJECT_TO_NETWORK: "Rejet direct vers un réseau adapté",
    DIRECT_REJECT_TO_EXIT: "Rejet direct vers un exutoire autorisé",
    TREATMENT_BEFORE_REJECTION_TO_NETWORK:
      "Traitement sur site à l’aide d’un filtre mobile (charbon actif) avant rejet vers un réseau ",
    TREATMENT_BEFORE_REJECTION_TO_ENV:
      "Traitement sur site à l’aide d’un filtre mobile (charbon actif) avant rejet vers le milieu naturel",
    ELIMINATION: "Elimination selon une filière adaptée",
    DISPOSABLE_MANUAL_SAMPLER_PEHD: "Préleveur manuel jetable en PEHD",
    DISPOSABLE_SAMPLER_POLYPROPYLENE_STERILE:
      "Echantillonneur polypropylène stérile à usage unique",
    INTERFACE_PROBE: "Sonde à interface",
  },
};
